import React, { useState, useEffect } from 'react';
import Dialog from './Dialog';
import ListBlacklist from './TabDrawer/ListBlacklist';
import useDetailMember from '../hooks/useDetailMember';

interface ITabState {
    data: any[],
    loading: boolean,
    total_data: number,
    total_page: number
}

interface IBlacklistDialog {
    userId: string,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: any) => void
}

const BlacklistDialog:React.FC<IBlacklistDialog> = (props) => {
    const { userId, dialogHandler } = props;

    const [itemOffset, setItemOffset] = useState(0);
    const [pageBlacklist, setPageBlacklist] = useState(1);
    const [dataBlacklist, setDataBlacklist] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });

    const { list, loading, totalList, totalPage } = useDetailMember({ path: '/blacklist', currentPage: pageBlacklist, userId, isTotalPage: true, limit: 2 });

    const onPageChange = (event: any) => {
        setPageBlacklist(event.selected + 1);
    };

    const fetchBlacklist = () => {
        setDataBlacklist({
            data: list,
            loading,
            total_data: totalList,
            total_page: totalPage
        });
    };

    useEffect(() => {
        fetchBlacklist();
    }, [loading]);

    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;

    return (
        <Dialog fullWidth closeBtn dialogHandler={dialogHandler}>
            <ListBlacklist
                from={itemOffset + 1}
                to={endOffset}
                data={dataBlacklist}
                page={pageBlacklist}
                onPageChange={(event: any) => {
                    onPageChange(event);
                    const newOffset = (event.selected * itemsPerPage) % totalList;
                    setItemOffset(newOffset);
                }}
            />
        </Dialog>
    );
};

export default BlacklistDialog;
