/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import useDate from '../../helpers/useDate';
import Pagination from '../Pagination';
import { Button } from '../Styled';
import { ActivityWrapper } from './ListActivity';
import BlacklistRecoverDialog from '../BlacklistRecoverDialog';
import type { IListActivity } from './ListActivity';

const BlacklistItemWrapper = styled.div`
    position: relative;
    display: block;
    color: var(--color-base-text-light);
    width: 100%;
    padding: 5px 1rem 15px;
    margin: 20px 0 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
`;

const BlacklistItemHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 0px 10px;
    border-bottom: 2px dashed #959598;
`;

const BlacklistItemBody = styled.div`
    position: relative;
    padding: 15px 0 0;
`;

export const PhotoWrapper = styled('div').attrs((props) => ({
    className: props.className
}))`
    width: 50px;
    height: 50px;
`;

interface IData {
    id: number,
    sender_id: string,
    receiver_id: string,
    name: string,
    role: string,
    reason: string,
    created_at: string,
    kontraktor_name: string,
    can_recover: boolean,
    recover_date: string,
    profile_image: string,
    photo_url: string[]
}

interface IItemActivity {
    data: IData
}

export const ItemActivity: React.FC<IItemActivity> = ({ data }) => {
    const { id, name, reason, kontraktor_name, created_at, can_recover, profile_image, recover_date, photo_url: photos } = data;
    const [isRecover, setIsRecover] = useState<boolean>(false);

    const handleRecover = () => {
        setIsRecover(true);
    };

    return (
        <BlacklistItemWrapper>
            <BlacklistItemHeader>
                <div className="row">
                    <div className="col-6">{useDate.getMomentDate(created_at, true)}</div>
                    <div className="col-6 text-end fw-bold">{`KG${id}`}</div>
                </div>
            </BlacklistItemHeader>
            <BlacklistItemBody>
                <div className="row">
                    <div className="col-6">
                        <div>
                            <p className="m-0 font-small">Alasan Blacklist</p>
                            <p className="m-0">{reason}</p>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                            <div className="rounded rounded-circle overflow-hidden d-flex align-items-center justify-content-center me-1" style={{ width: '24px', height: '24px' }}>
                                <img src={profile_image} width="100%" alt="profile" />
                            </div>
                            <p className="mb-0 me-1 color-gray-primary">by</p>
                            <p className="m-0">{name}</p>
                            {kontraktor_name && (
                                <p className="m-0 ms-1 fw-bold color-blue">{`(${kontraktor_name})`}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                {photos.length > 0 && (
                                    <div>
                                        <p className="mb-1 font-small">Foto</p>
                                        <div className="d-flex">
                                            {photos.map(({ photo_url }: any) => (
                                                <PhotoWrapper key={photo_url} className="d-flex align-items-center justify-content-center overflow-hidden rounded me-2">
                                                    <img src={photo_url} alt="attachment" width="100%" />
                                                </PhotoWrapper>
                                            ))}
                                        </div>
                                        {photos.length > 5 && (
                                            <p className="m-0 fw-semibold color-cs-primary cursor-pointer">Lihat lebih banyak</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                {recover_date && <p className="color-gray-primary mb-1">Dipulihkan</p>}
                                {recover_date ? <p className="m-0 fw-bold">{useDate.formatDate(recover_date)}</p> : (can_recover ? <Button onClick={handleRecover} rounded gradient>Pulihkan</Button> : '')}
                            </div>
                        </div>
                    </div>
                </div>
            </BlacklistItemBody>

            {/* Dialog */}
            {isRecover && <BlacklistRecoverDialog dialogHandler={(showDialog: boolean) => setIsRecover(showDialog)} blacklist_id={id} /> }

        </BlacklistItemWrapper>
    );
};

const ListBlacklist: React.FC<IListActivity> = (props) => {
    const { data, page, from, to, onPageChange } = props;

    return (
        <ActivityWrapper>
            <div className="mx-cs-2">
                {data.total_data > 0 && data.data.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ItemActivity key={index} data={item} />
                ))}
            </div>
            <div className="mx-cs-2">
                <Pagination
                    from={from}
                    to={to}
                    totalData={data.total_data}
                    currentPage={page}
                    pageCount={data.total_page}
                    onPageChange={onPageChange}
                />
            </div>
        </ActivityWrapper>
    );
};

export default ListBlacklist;
