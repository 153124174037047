import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardLayout from '../../layout/DashboardLayout';

class PaymentStatus extends React.Component {
    render(): React.ReactNode {
        return (
            <DashboardLayout activeMenu="status-pembayaran">
                <div className="container-fluid py-2 px-3">
                    <Outlet />
                </div>
            </DashboardLayout>
        );
    }
}

export default PaymentStatus;
