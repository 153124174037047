import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CONSTANT from '../../../../constant';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const tokenAuth = useLocalStorage.get(CONSTANT.APP.SESSION_KEY);

interface AuthState {
    isRequest: boolean,
    isLoggedIn: boolean,
    error: '',
    token: string
}

const initialState: AuthState = {
    isRequest: false,
    isLoggedIn: !!tokenAuth,
    error: '',
    token: tokenAuth || ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Login Reducer
        loginRequest: (state: any) => {
            state.isRequest = true;
            state.isLoggedIn = false;
            state.error = '';
            state.token = '';
        },
        loginFailure: (state: any, action: PayloadAction<string>) => {
            state.isRequest = false;
            state.error = action.payload;
        },
        loginSuccess: (state: any, action: PayloadAction<string>) => {
            state.isRequest = false;
            state.isLoggedIn = true;
            state.error = '';
            state.token = action.payload;
        },
        resetForm: (state: any) => {
            state.isRequest = false;
            state.isLoggedIn = false;
            state.error = '';
            state.token = '';
        },
        // Logout Reducer
        logoutRequest: (state: any) => {
            state.isRequest = true;
        },
        logoutSuccess: (state: any) => {
            state.isRequest = false;
            state.isLoggedIn = false;
            state.error = '';
            state.token = '';
        },

        forgortPasswordRequest: (state: any, action: any) => {
            state.isLoggedIn = action.payload;
        }
    }
});

export const {
    loginRequest,
    loginFailure,
    loginSuccess,
    resetForm,
    logoutRequest,
    logoutSuccess
} = authSlice.actions;

export default authSlice.reducer;
