import React, { useEffect } from 'react';
import feather from 'feather-icons';
import styled from 'styled-components';

const IconWrapper = styled.i<{ small?: boolean, large?: boolean, white?: boolean}>`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    ${(props) => (props.white ? 'color: #fff;' : '')}
    ${(props) => (!props.small && !props.large ? 'width: 18px; height: 18px;' : '')}
    ${(props) => (props.small ? `
        width: 16px;
        height: 16px;
    ` : '')}
    ${(props) => (props.large ? `
        width: 20px;
        height: 20px;
    ` : '')}
`;

const CustomIconWrapper = styled.div<{ small?: boolean, large?: boolean, width?: string, height?: string }>`
    position: relative;
    display: inline-block;
    width: ${(props) => (props.width !== '0' ? `${props.width}px` : 'auto')};
    height: ${(props) => (props.height !== '0' ? `${props.height}px` : 'auto')};
    vertical-align: middle;
    ${(props) => (props.small ? `
        width: 16px;
        height: 16px;
    ` : '')}
    ${(props) => (props.large ? `
        width: 20px;
        height: 20px;
    ` : '')}

    img {
        position: relative;
        display: block;
        height: 100%;
    }
`;

interface IIcon {
    icon: any,
    custom?: boolean,
    small?: boolean,
    large?: boolean,
    width?: string,
    height?: string,
    white?: boolean
}

const Icon = (props: IIcon) => {
    const { icon, custom, small, large, width, height, white } = props;

    useEffect(() => {
        feather.replace();
    }, []);

    return (
        <>
            {!custom && (
                <IconWrapper data-feather={icon} small={small} large={large} white={white} />
            )}
            {custom && (
                <CustomIconWrapper small={small} large={large} width={width} height={height}>
                    <img src={icon} alt="icon" />
                </CustomIconWrapper>
            )}
        </>
    );
};

Icon.defaultProps = {
    custom: false,
    small: false,
    large: false,
    width: '0',
    height: '0',
    white: false
};

export default Icon;
