/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '../../../components/Dialog';
import { Button, Label } from '../../../components/Styled';
import API from '../../../configs/api';

const DialogHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;

    .profile-img {
        position: relative;
        display: block;
        width: 65px;
        height: 65px;
        margin: auto;
        
        img {
            height: 100%;
            border-radius: 100px;
        }
    }
`;

const ReasonWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;

    #reason-max-length {
        position: absolute;
        right: 10px;
        bottom: 10px;
        opacity: 70%;
    }
`;

const ReasonInput = styled.textarea`
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid #D8CFCD;
    border-radius: 20px;
    padding: 5px 1rem;
    outline: none;
    resize: none;
`;

interface IDialogRejectPayment {
    id: any,
    data: any,
    // eslint-disable-next-line no-unused-vars
    onRejectPayment: (reason: string) => void,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (showDialog: boolean) => void
}

const DialogRejectPayment: React.FC<IDialogRejectPayment> = (props) => {
    const { id, data } = props;
    const [reason, setReason] = useState('');
    const { onRejectPayment, dialogHandler } = props;

    const handleSubmit = () => {
        const payload = {
            params: `/${data.user_id}/${id}`,
            body: {
                reason
            }
        };
        API.rejectPayment(payload).then(() => {
            onRejectPayment(reason);
            setTimeout(() => {
                dialogHandler(false);
            }, 300);
        });
    };

    return (
        <Dialog dialogHandler={dialogHandler}>
            <DialogHeader>
                <div className="profile-img">
                    <img src={data.profile_image} alt={data.name} />
                </div>
                <div className="container">
                    <p className="mb-1 font-large font-bold">{data.name}</p>
                    <span>{data.user_id}</span>
                    <span> | </span>
                    <span className="font-bold">{data.role}</span>
                </div>
            </DialogHeader>
            <div className="lines mt-1 my-3" />
            <Label className="font-small">Masukkan alasan penolakan <span style={{ color: '#D9272D' }}>*</span></Label>
            <ReasonWrapper>
                <ReasonInput className="textarea" rows={6} maxLength={100} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReason(event.target.value)} value={reason} />
                <span id="reason-max-length">{100 - reason.length}</span>
            </ReasonWrapper>
            <div className="row mt-3 mb-1">
                <div className="col-4" />
                <div className="col-4">
                    <Button border rounded textBlack block onClick={() => dialogHandler(false)}>Kembali</Button>
                </div>
                <div className="col-4">
                    <Button gradient rounded block disabled={reason.length < 1} onClick={() => handleSubmit()}>Simpan</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogRejectPayment;
