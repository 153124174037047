import action from '..';
import CONSTANT from '../../../../constant';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import API from '../../../api';
import { loginFailure, loginRequest, loginSuccess, logoutRequest, logoutSuccess } from '../../reducer/auth';
import { setUser } from '../../reducer/user';

const login = (payload: any) => {
    const data = { body: payload };
    return (dispatch: any) => {
        dispatch(loginRequest());
        API.login(data).then((response: any) => {
            const { token } = response.data;

            const profile = response.data;
            delete profile.token;
            dispatch(setUser(profile));

            useLocalStorage.set(CONSTANT.APP.SESSION_KEY, token);
            useLocalStorage.set(CONSTANT.APP.USER_PROFILE, profile);
            setTimeout(() => {
                dispatch(loginSuccess(token));
            }, 1000);
        }).catch((err: any) => {
            if (err.message && err.message.includes('Kata')) {
                dispatch(loginFailure('password'));
            } else if (err.message && err.message.includes('User')) {
                dispatch(loginFailure('user'));
            }
        });
    };
};

const logout = () => (dispatch: any) => {
    dispatch(logoutRequest());
    dispatch(action.showLoading());
    localStorage.clear();
    setTimeout(() => {
        dispatch(logoutSuccess());
        window.location.href = '/auth/login';
    }, 1500);
};

export {
    login,
    logout
};
