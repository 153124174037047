import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppReducer';

// export type ProtectedRouteProps = {
//     isAuthenticated: boolean;
//     authenticationPath: string;
//     // eslint-disable-next-line no-undef
//     outlet: JSX.Element;
// };

interface IPrivateRoute {
    children: any
}

const PrivateRoute: React.FC<IPrivateRoute> = (props) => {
    const { children } = props;
    const { isLoggedIn } = useAppSelector((state) => state.authReducer);

    if (!isLoggedIn) {
        return <Navigate to="/auth/login" />;
    }
    return children;
};

export default PrivateRoute;
