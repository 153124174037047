import { createSlice } from '@reduxjs/toolkit';
import CONSTANT from '../../../../constant';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const storedProfile = useLocalStorage.get(CONSTANT.APP.USER_PROFILE);

const initialState = {
    profile: storedProfile || {}
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: any, action: any) => {
            state.profile = action.payload;
        }
    }
});

export const {
    setUser
} = userSlice.actions;

export default userSlice.reducer;
