import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import iconDelete from '../assets/icon/icon-delete.svg';

const NumberKeyWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 270px;
    height: 100%;
    flex-direction: row;
    align-items: center;
`;

const NumberKeyItem = styled.div`
    position: relative;
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: var(--font-bold);
    background: #fff;
    padding: 20px 10px;
    margin: 0 10px;
    border: 1px solid #D1D1D1;
    border-radius: 15px;
    transition: background .3s ease;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, .03);
    }
`;

interface INumberKeyboard {
    value: string,
    // eslint-disable-next-line no-unused-vars
    onClick: (val: string) => void
}

const NumberKeyboard: React.FC<INumberKeyboard> = (props) => {
    const { value, onClick } = props;

    const onPinClick = (newValue: string | number) => {
        onClick(value + newValue);
    };

    const onDeleteClick = () => {
        const defaultValue = value.replaceAll(',', '').replaceAll('.', '');
        const newValue = defaultValue.substring(0, defaultValue.length - 1);
        onClick(newValue);
    };

    return (
        <NumberKeyWrapper id="number-keyboard">
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="row justify-content-between">
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(1)}>1</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(2)}>2</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(3)}>3</NumberKeyItem>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="row justify-content-between">
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(4)}>4</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(5)}>5</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(6)}>6</NumberKeyItem>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="row justify-content-between">
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(7)}>7</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(8)}>8</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(9)}>9</NumberKeyItem>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="row justify-content-between">
                        <div className="col-4" />
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onPinClick(0)}>0</NumberKeyItem>
                        </div>
                        <div className="col-4">
                            <NumberKeyItem onClick={() => onDeleteClick()}>
                                <Icon custom icon={iconDelete} />
                            </NumberKeyItem>
                        </div>
                    </div>
                </div>
            </div>
        </NumberKeyWrapper>
    );
};

export default NumberKeyboard;
