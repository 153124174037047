import React from 'react';
import styled from 'styled-components';

const ShimmerWrapper = styled.div`
    position: relative;
    display: block;
    background: rgba(255, 255, 255, 1);
    border-radius: 100px;
    overflow: hidden;
    opacity: 40%;

    &.dark {
        background: rgba(224, 224, 224, 1);
    }
`;

const ShimmerLoading = styled.div`
    height: 100%;
    animation-name: shimmer;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: linear-gradient(to right, rgba(200, 200, 200, 0.4) 8%, rgba(255, 255, 255, .1) 18%, rgba(200, 200, 200, 0.4) 33%);
    background-size: 1000px 100%;
`;

interface IShimmer {
    style?: React.CSSProperties,
    className?: string,
    type?: string
}

const Shimmer: React.FC<IShimmer> = (props) => {
    const { style, className, type } = props;
    return (
        <>
            {type === 'dot' && (
                <ShimmerWrapper className={className} style={{ width: 50, height: 50, ...style }}>
                    <ShimmerLoading />
                </ShimmerWrapper>
            )}
            {type === 'line' && (
                <ShimmerWrapper className={className} style={{ width: '100%', height: '14px', ...style }}>
                    <ShimmerLoading />
                </ShimmerWrapper>
            )}
        </>
    );
};

Shimmer.defaultProps = {
    style: {},
    type: 'line',
    className: ''
};

export default Shimmer;
