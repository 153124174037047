import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import illustrasiKanggo from '../assets/img/illustrasi-kanggo-logout.png';
import { Button } from './Styled';
import API from '../configs/api';
import action from '../configs/redux/action';
import { useAppDispatch } from '../hooks/useAppReducer';

const LogoutImageWrapper = styled.div`
    position: relative;
    display: block;
    height: 140px;
    text-align: center;

    img {
        height: 100%;
    }
`;

interface IBlacklist {
    blacklist_id: number | string,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const BlacklistRecoverDialog: React.FC<IBlacklist> = (props) => {
    const { blacklist_id, dialogHandler } = props;
    const dispatch = useAppDispatch();

    const handleRecover = () => {
        const payload = {
            body: {
                blacklist_id
            }
        };
        API.recoverBlacklist(payload).then((result: any) => {
            dialogHandler(false);
            dispatch(action.showToast('success', result.message));
        }).catch((err) => {
            dialogHandler(false);
            dispatch(action.hideLoading());
            dispatch(action.showToast('error', err.message));
        });
    };

    return (
        <Dialog closeBtn dialogHandler={dialogHandler}>
            <LogoutImageWrapper>
                <img src={illustrasiKanggo} alt="PT. Tenaga Kanggo Indonesia" />
            </LogoutImageWrapper>
            <p className="font-bold text-center mt-3">Pulihkan Blacklist Sekarang?</p>
            <p className="text-center my-3" style={{ width: '27em' }}>Seluruh organisasi dapat melihat status blacklist akan dipulihkan pastikan kamu sudah di rekonfirmasi</p>
            <div className="row">
                <div className="col-6">
                    <Button block border rounded textBlack primary onClick={() => dialogHandler(false)}>Batalkan</Button>
                </div>
                <div className="col-6">
                    <Button block rounded gradient onClick={handleRecover}>OK. Pulihkan</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default BlacklistRecoverDialog;
