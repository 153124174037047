import { login, logout } from './auth';
import { showLoading, hideLoading, logoutRequest, logoutCancel, showToast, drawerProfile, drawerDetail, setWaitingPaymentCounter, hideWaitingPayment } from './ui';
import { getProfile } from './user';

const action = {
    login,
    logout,

    getProfile,

    showLoading,
    hideLoading,
    showToast,
    logoutRequest,
    logoutCancel,
    drawerProfile,
    drawerDetail,
    setWaitingPaymentCounter,
    hideWaitingPayment
};

export default action;
