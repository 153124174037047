import React from 'react';
import { Card } from '../../../components/Styled';
import useCurrency from '../../../helpers/useCurrency';

interface ICardInformation {
    data: any
}

const CardInformation: React.FC<ICardInformation> = (props) => {
    const { data } = props;

    return (
        <Card shadow>
            <div className="row">
                <div className="col-12 mb-2">
                    <p className="m-0 font-bold">Ringkasan Transaksi Keuangan Per-proyek</p>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pengajuan</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{`Rp. ${useCurrency(data.total_pengajuan)}`}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pembayaran Berhasil</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{`Rp. ${useCurrency(data.total_payment_success)}`}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pembayaran Ditolak</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{`Rp. ${useCurrency(data.total_payment_reject)}`}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pembayaran Gagal</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{`Rp. ${useCurrency(data.total_payment_failed)}`}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pengajuan Ulang</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{`Rp. ${useCurrency(data.total_repayment)}`}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total PM</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{data.total_pm}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Mandor</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{data.total_mandor}</span>
                </div>
                <div className="col-6 mb-1">
                    <span>Total Pelaksana/Logistik</span>
                </div>
                <div className="col-6 mb-1 text-end">
                    <span className="font-bold">{data.total_pelaksana_logistik}</span>
                </div>
            </div>
        </Card>
    );
};

export default CardInformation;
