import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import Pagination from './Pagination';
import { useAppSelector } from '../hooks/useAppReducer';

interface ITableProps {
    columns: any[],
    data: any[],
    loading: boolean,
    totalList: number,
    paginate?: boolean,
    currentPage?: number,
    expandable?: boolean,
    selectable?: boolean,
    expandableComponents?: any,
    clearSelectedRows?: boolean,
    onSelectedRowsChange?: any,
    onRowClicked?: any,
    pageCount?: number,
    from?: number,
    to?: number,
    marginTop?: string,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const Table: React.FC<ITableProps> = (props) => {
    const { columns, data, loading, totalList, onPageChange, paginate, currentPage, expandable, selectable, expandableComponents, clearSelectedRows, onSelectedRowsChange, onRowClicked, pageCount, from, to, marginTop } = props;
    const { darkMode } = useAppSelector((state) => state.uiReducer);

    // eslint-disable-next-line react/no-unstable-nested-components
    const TablePagination = () => {
        return (
            <Pagination marginTop={marginTop || '0px'} from={from} to={to} totalData={totalList} currentPage={currentPage!} pageCount={pageCount!} onPageChange={onPageChange} />
        );
    };

    createTheme('costum-dark', {
        background: {
            default: 'transparent'
        },
        divider: {
            default: '#414C88'
        }
    }, 'dark');

    createTheme('costum-light', {
        background: {
            default: 'transparent'
        }
    }, 'light');

    return (
        <DataTable
            theme={darkMode ? 'costum-dark' : 'costum-light'}
            noHeader
            columns={columns}
            data={data}
            progressPending={loading}
            pagination={paginate}
            paginationServer
            paginationTotalRows={totalList}
            paginationComponent={TablePagination}
            expandableRows={expandable}
            expandableRowsHideExpander
            expandableRowExpanded={() => true}
            expandableRowsComponent={expandableComponents}
            selectableRows={selectable}
            pointerOnHover
            highlightOnHover
            clearSelectedRows={clearSelectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
            onRowClicked={onRowClicked}
            onChangePage={(page: number) => {
                onPageChange(page);
            }}
        />
    );
};

Table.defaultProps = {
    currentPage: 0,
    paginate: false,
    expandable: false,
    expandableComponents: undefined,
    selectable: false,
    clearSelectedRows: false,
    onSelectedRowsChange: undefined,
    onRowClicked: undefined,
    pageCount: 0,
    from: 1,
    to: 10,
    marginTop: '10px'
};

export default Table;
