import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/Icon';
import iconDocument from '../../../assets/icon/icon-document-flat.svg';
import { Button, ButtonIcon } from '../../../components/Styled';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';

const FloatingWaitingPaymentWrapper = styled.div<{visible?: boolean}>`
    position: fixed;
    width: 30%;
    padding: 10px 15px;
    background: linear-gradient(90deg, #EB4600 0%, #EBA900 100%);
    border-radius: 20px;
    visibility: visible;
    bottom: 30px;
    right: 1.5rem;
    z-index: 10;
    transition: .3s ease;

    ${(props) => (props.visible ? `
        right: -120%;
        transform: none;
        visibility: hidden;
    ` : '')}
`;

interface IFloatingWaitingPayment {
    counter: number
}

const FloatingWaitingPayment: React.FC<IFloatingWaitingPayment> = (props) => {
    const { counter } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const visible = useAppSelector((state) => state.uiReducer.waitingPaymentVisible);

    const onClose = () => {
        dispatch(action.hideWaitingPayment());
    };

    return (
        <FloatingWaitingPaymentWrapper visible={!visible}>
            <div className="row mb-2 align-items-center">
                <div className="col-6">
                    <Icon custom icon={iconDocument} />
                </div>
                <div className="col-6 text-end">
                    <ButtonIcon onClick={() => onClose()}>
                        <Icon white icon="x" />
                    </ButtonIcon>
                </div>
            </div>
            <p className="m-0 font-bold text-white">
                {counter}
                <span> Permintaan Pembayaran</span>
            </p>
            <p className="text-white mb-2">Menunggu persetujuan pembayaran upah mandor</p>
            <Button block primary rounded onClick={() => navigate('/status-pembayaran')}>Lihat Detail</Button>
        </FloatingWaitingPaymentWrapper>
    );
};

export default FloatingWaitingPayment;
