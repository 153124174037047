import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import illustrasiKanggo from '../assets/img/illustrasi-kanggo-logout.png';
import { Button } from './Styled';
import { useAppDispatch } from '../hooks/useAppReducer';
import action from '../configs/redux/action';

const LogoutImageWrapper = styled.div`
    position: relative;
    display: block;
    height: 140px;
    text-align: center;

    img {
        height: 100%;
    }
`;

const LogoutDialog: React.FC = () => {
    const dispatch = useAppDispatch();

    return (
        <Dialog>
            <LogoutImageWrapper>
                <img src={illustrasiKanggo} alt="PT. Tenaga Kanggo Indonesia" />
            </LogoutImageWrapper>
            <p className="font-bold text-center my-3">Keluar dari Dashboard?</p>
            <div className="row">
                <div className="col-6">
                    <Button block border rounded textBlack primary onClick={() => dispatch(action.logoutCancel())}>Batalkan</Button>
                </div>
                <div className="col-6">
                    <Button block rounded gradient onClick={() => dispatch(action.logout())}>OK. Pulihkan</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default LogoutDialog;
