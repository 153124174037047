import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './Styled';

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <Button block border rounded textBlack onClick={() => navigate(-1)}>Kembali</Button>
    );
};

export default BackButton;
