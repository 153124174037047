const useCurrency = (value: number, separator?: string) => {
    // const formatedPrice = new Intl.NumberFormat().format(value);

    // if (formatedPrice === 'NaN') return '0';
    // return formatedPrice;

    if (typeof value === 'undefined') {
        return '';
    }

    let initSeparator = '.';

    if (separator) {
        initSeparator = separator;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, initSeparator);
};

export default useCurrency;
