/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import defaultImg from '../../../@static/default.png';
import useDate from '../../../helpers/useDate';

const LogIndex = styled.div`
    position: relative;
    display: block;
    width: 50px;
    height: 100%;

    #bullet {
        position: absolute;
        display: block;
        width: 28px;
        height: 28px;
        background: #F2B7B9;
        border: 4px solid #fff;
        border-radius: 100px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        
        div {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            background: var(--color-base-primary-light);
            border-radius: 100px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    #lines {
        position: relative;
        height: 100px;
        width: 2px;
        left: 50%;
        top: 0;
        background: #EEEEEE;
        transform: translateX(-50%);
        z-index: 1;
    }
`;

const LogItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 0;

    &:last-child
        ${LogIndex} {
            &::before {
                content: none;
            }
        }
    }
`;

const ProfileLog = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 1rem;

    #log-message {
        p {
            margin: 0;
        }
    }
`;

interface ILogItem {
    data: any[]
}

const LogItem: React.FC<ILogItem> = (props) => {
    const { data } = props;
    return (
        <>
            {data.map((item: any, idx: any) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={idx}>
                    <LogItemWrapper>
                        <LogIndex>
                            <div id="lines" />
                            <div id="bullet">
                                <div />
                            </div>
                        </LogIndex>
                        <ProfileLog>
                            <div className="row">
                                <div className="col-profile">
                                    <div className="profile-img">
                                        <img src={defaultImg} alt="Imgae" />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <p className="m-0">{item.name}</p>
                                    <p className="m-0">
                                        <span style={{ color: '#959598', marginRight: 5 }}>{item.role}</span>
                                        {item.user_id}
                                    </p>
                                    <div id="log-message" dangerouslySetInnerHTML={{ __html: item.message }} />
                                </div>
                                <div className="col text-end">
                                    <p className="m-0" style={{ color: '#959598' }}>
                                        {useDate.namedDate(item.created_at, true)}
                                    </p>
                                </div>
                            </div>
                        </ProfileLog>
                    </LogItemWrapper>
                </React.Fragment>
            ))}
        </>
    );
};

export default LogItem;
