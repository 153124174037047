import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardLayout from '../../layout/DashboardLayout';

class Member extends React.Component<any, any> {
    render(): React.ReactNode {
        return (
            <DashboardLayout activeMenu="daftar-anggota">
                <div className="container-fluid p-3 p-md-4">
                    <Outlet />
                </div>
            </DashboardLayout>
        );
    }
}

export default Member;
