const useLocalStorage = {
    get: (keyName: string) => {
        const defaultValue = '';
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                return JSON.parse(value);
            }
            return defaultValue;
        } catch (error) {
            return defaultValue;
        }
    },
    set: (keyName: string, newValue: any) => {
        try {
            const value = JSON.stringify(newValue);
            window.localStorage.setItem(keyName, value);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }
};

export default useLocalStorage;
