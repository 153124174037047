import iconBuilding from '../assets/icon/icon-building.svg';
import iconChart from '../assets/icon/icon-chart.svg';
import iconDocument from '../assets/icon/icon-document.svg';
import iconPaper from '../assets/icon/icon-paper-download.svg';
import iconProfile from '../assets/icon/icon-profile-color.svg';
import iconPeopleAdd from '../assets/icon/icon-people-team-add.svg';
import iconWallet from '../assets/icon/icon-wallet.svg';
import iconPasswordCursor from '../assets/icon/icon-password-cursor.svg';
import iconPassword from '../assets/icon/icon-password.svg';
import iconBlacklist from '../assets/icon/icon-user-blacklist.svg';
// import iconMessage from '../assets/icon/icon-message.svg';
import iconAbout from '../assets/icon/icon-about.svg';
import iconQuestion from '../assets/icon/icon-question.svg';

// drawer menu item list
const menuList = [
    {
        id: 0,
        title: 'Daftar Perusahaan',
        icon: iconChart,
        tag: 'dashboard',
        drawer: true,
        // roles: ['FINANCE KONTRAKTOR']
        roles: ['ADMIN KANGGO']
    },
    {
        id: 1,
        title: 'Dashboard',
        icon: iconChart,
        tag: 'dashboard',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
        // roles: ['ADMIN KANGGO']
    },
    {
        id: 2,
        title: 'Proyek Kontraktor',
        icon: iconBuilding,
        tag: 'proyek-kontraktor',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 3,
        title: 'Status Pembayaran',
        icon: iconWallet,
        tag: 'status-pembayaran',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 4,
        title: 'Laporan Pembayaran',
        icon: iconDocument,
        tag: 'laporan-pembayaran',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 5,
        title: 'Daftar Anggota',
        icon: iconPeopleAdd,
        tag: 'daftar-anggota',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 6,
        title: 'Blacklist',
        icon: iconBlacklist,
        tag: 'blacklist',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 7,
        title: 'Log',
        icon: iconPaper,
        tag: 'log',
        drawer: true,
        roles: ['FINANCE KONTRAKTOR']
    },
    {
        id: 8,
        title: 'Data Kontraktor',
        icon: iconChart,
        tag: 'data-kontraktor'
    },
    {
        id: 9,
        title: 'Profile',
        icon: iconProfile,
        tag: 'profile'
    },
    {
        id: 10,
        title: 'Ubah Pin',
        icon: iconPasswordCursor,
        tag: 'pin'
    },
    {
        id: 11,
        title: 'Ubah Password',
        icon: iconPassword,
        tag: 'password'
    },
    {
        id: 12,
        title: 'Tentang Kanggo',
        icon: iconAbout,
        tag: 'tentang'
    },
    {
        id: 13,
        title: 'Bantuan',
        icon: iconQuestion,
        tag: 'bantuan'
    }
];

export default menuList;
