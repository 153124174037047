/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { Button } from '../../../components/Styled';
import CONSTANT from '../../../constant';
import Help from '../components/Help';
// import useLocalStorage from '../../../hooks/useLocalStorage';
// import { useParams } from 'react-router-dom';
// import API from '../../../configs/api';
import TabLink from '../components/TabLink';

const PaymentFrameWrapper = styled.div`
    position: relative;
    display: block;
    width: 400px;
    height: 100%;
    max-height: 500px;
    margin: 0 auto;

    iframe {
        width: 100%;
        height: 500px;
        overflow: hidden;
    }
`;

const PaymentMethod: React.FC = () => {
    const { external_id } = useParams();
    const navigate = useNavigate();
    const [showHelp, setShowHelp] = useState(false);

    const loadPaymentFrame = () => {
        if (!external_id) {
            navigate('/not-found', { replace: true });
        }
        return CONSTANT.APP.BASE_PAYMENT_URL + external_id;
    };

    return (
        <>
            <TabLink tabActive="menunggu-pembayaran" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <div className="row align-items-center border-bottom mt-2 pb-3">
                    <div className="col-6">
                        <div role="button" id="back" onClick={() => navigate('/status-pembayaran/menunggu-pembayaran')}>
                            <Icon icon="arrow-left" />
                            <span className="ms-3">Pilih Metode Pembayaran</span>
                        </div>
                    </div>
                    <div className="col-6 text-end" style={{ position: 'relative' }}>
                        <p className="m-0" onClick={() => setShowHelp(!showHelp)}>
                            Butuh bantuan?
                            <span className="text-primary font-bold" role="button"> Hubungi CS Kanggo</span>
                            {showHelp && (
                                <Help />
                            )}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col" />
                    <div className="col">
                        <PaymentFrameWrapper>
                            <iframe id="payment-frame" title="Payment Method" src={loadPaymentFrame()} />
                        </PaymentFrameWrapper>
                    </div>
                    <div className="col text-end pt-3">
                        <Button border rounded primary>Detail Transaksi</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentMethod;
