const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;
const PAYMENT_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PAY_URL : process.env.REACT_APP_PROD_PAY_URL;
// const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_PROD_API_URL;
// const PAYMENT_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PROD_PAY_URL : process.env.REACT_APP_PROD_PAY_URL;
const CONSTANT = {
    //  Constant for the App
    APP: {
        SESSION_KEY: 'APP_SESSION',
        USER_PROFILE: 'USER_PROFILE',
        DRAWER_PROFILE: 'DRAWER_PROFILE',
        BASE_PAYMENT_URL: PAYMENT_URL || ''
    },
    //  Constant for the Api
    API: {
        API_KEY: 'vDt8loYSe9L4/Jrs9Fq5QUUSXqwGvqNEOlM52mLeGarPrATAmaRUREpAfHg8Gbi09',
        BASE_URL: API_URL || '',
        LOGIN_URL: '/cms/v1/login',
        CARD_INFO: '/cms/v1/dashboard/card-info',
        LIST_TRANSACTION: '/cms/v1/dashboard/transaction',
        LIST_WORKER: '/cms/v1/dashboard/worker',
        PROJECT_ACTIVE_REQUEST: '/cms/v1/dashboard/project-request',
        PROJECT_CONTRACTOR: '/cms/v1/project-kontraktor',
        MEMBER: '/cms/v1/member',
        CREATE_MEMBER: '/cms/v1/member/create',
        DELETE_MEMBER: '/cms/v1/member/delete',
        MEMBER_DETAIL: '/cms/v1/member/detail',
        BLACKLIST: '/cms/v1/blacklist',
        RECOVER_BLACKLIST: '/cms/v1/member/detail/blacklist',
        PAYMENT_STATUS: '/cms/v1/status-payment',
        PAYMENT_STATUS_PENDING: '/cms/v1/status-payment/pending',
        PAYMENT_STATUS_PENDING_DETAIL: '/cms/v1/status-payment/pending/detail',
        PAYMENT_STATUS_WAITING: '/cms/v1/status-payment/waiting',
        PAYMENT_STATUS_WAITING_DETAIL: '/cms/v1/status-payment/waiting/detail',
        PAYMENT_STATUS_IN_PROGRESS: '/cms/v1/status-payment/in-progress',
        PAYMENT_STATUS_IN_PROGRES_DETAIL: '/cms/v1/status-payment/waiting/detail',
        PAYMENT_STATUS_FAILED: '/cms/v1/status-payment/failed-payment',
        PAYMENT_STATUS_SUCCESS: '/cms/v1/status-payment/success-payment',
        PAYMENT_STATUS_SUCCESS_DETAIL: '/cms/v1/status-payment/detail',
        PAYMENT_STATUS_ADMIN_FEE: '/cms/v1/status-payment/admin-fee',
        ACCEPT_PAYMENT: '/cms/v1/status-payment/pay',
        REJECT_PAYMENT: '/cms/v1/status-payment/reject_payment',
        IN_PROGRESS_PAY: '/cms/v1/status-payment/in-progress/pay',
        IN_PROGRESS_CANCEL: '/cms/v1/status-payment/in-progress/cancel-payment',
        FEE_ADMIN: '/cms/v1/status-payment/admin-fee',
        PROFILE: '/cms/v1/profile',
        CHANGE_PASSWORD: '/cms/v1/profile/change/password',
        CHANGE_PIN: '/cms/v1//change/pin',
        MATCH_PIN: '/cms/v1/match-pin',
        LOG: '/cms/v1/log',
        CHECK_ACCOUNT_BANK: '/cms/v1/transaction/check-account-bank',
        REPORT_PAYMENT: '/cms/v1/report-payment',
        LIST_PROJECT: '/cms/v1/report-payment/project'
    }
};

export default CONSTANT;
