import styled from 'styled-components';

export const TextInput = styled.input<{block?: boolean, rounded?: boolean, border?: boolean, error?: boolean}>`
    position: relative;
    height: 48px;
    width: ${(props) => (props.block ? '100%' : 'auto')};
    display: ${(props) => (props.block ? 'block' : 'inline-block')};
    padding: 6px 1rem;
    margin: 0;
    font-size: var(--font-normal);
    color: var(--color-base-text-light);
    border-radius: ${(props) => (props.rounded ? '100px' : '10px')};
    outline: none;
    border: ${(props) => (props.border ? '1px solid var(--color-card-border)' : 'none')};
    cursor: pointer;
    background: var(--color-input);

    ${(props) => (props.error ? 'border-color: #D9272D;' : '')}

    &:disabled {
        background: #F7F5F5;
        color: #8b8b8b;
        cursor: no-drop;
    }
`;

export const TextInputGroup = styled.div<{border?: boolean, rounded?: boolean, error?: boolean}>`
    position: relative;
    display: block;
    border: ${(props) => (props.border ? '1px solid #8b8b8b' : 'none')};
    border-radius: ${(props) => (props.rounded ? '100px' : '10px')};

    #input-icon {
        position: absolute;
        display: flex;
        height: 100%;
        width: auto;
        padding: 0 10px;
        align-items: center;
        right: 0;
        top: 0;
        color: #c0c0c0;
        z-index: 1;
        &:hover {
            color: var(--color-dark);
        }
        &.left {
            left: 0;
            right: auto;

        }

        &+${TextInput} {
            height: 44px;
            padding: 6px 2rem !important;
        }
    }

    ${(props) => (props.error ? 'border-color: #D9272D;' : '')}

    ${TextInput} {
        border: none;
        margin: 0;
    }
`;
