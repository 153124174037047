import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import Drawer from '../../../components/Drawer';
import RowProfileCard from '../../../components/RowProfileCard';
import API from '../../../configs/api';
import useCurrency from '../../../helpers/useCurrency';

const DrawerHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

const ListTransactionWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0 5px;
`;

interface IDrawerListTransaction {
    status: string,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

const DrawerListTransaction: React.FC<IDrawerListTransaction> = (props) => {
    const { status, drawerHandler } = props;
    const [data, setData] = useState<any>([]);
    const [isPending, setPending] = useState(true);

    const fetchData = () => {
        const type = status;
        const payload = {
            query: { type }
        };
        API.listTransaction(payload).then((result: any) => {
            setData(result.data);
            setPending(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ** Table List Transaction
    const columns = [
        {
            name: 'Tanggal Transfer',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.created_at
        },
        {
            name: 'Kode Proyek',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.project_code
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '180px',
            selector: (row: any) => row.project_name
        },
        {
            name: 'Client',
            sortable: true,
            minWidth: '220px',
            selector: (row: any) => row.client_name
        },
        {
            name: 'Nama PM',
            allowOverflow: true,
            style: {
                color: '#D9272D'
            },
            cell: (row: any) => <RowProfileCard data={row.pm} />
        },
        {
            name: 'Anggota',
            sortable: true,
            minWidth: '50px',
            selector: (row: any) => row.total_workers
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => `Rp. ${useCurrency(row.total_request)}`
        },
        {
            name: 'Status',
            sortable: true,
            minWidth: '130px',
            selector: () => status
        }
    ];

    return (
        <Drawer right drawerHandler={drawerHandler}>
            <DrawerHeader className="mb-4">
                {status === 'PAID' && (<p className="m-0 font-bold">Total Sudah Dibayarkan</p>)}
                {status === 'PENDING' && (<p className="m-0 font-bold">Total Pembayaran Pending</p>)}
                {status === 'FAILED' && (<p className="m-0 font-bold">Total Pembayaran Gagal</p>)}
                <span className="font-bold">{data.length}</span>
                {status === 'PAID' && (<span> Pembayaran disetujui</span>)}
                {status === 'PENDING' && (<span> Pengajuan pembayaran</span>)}
                {status === 'FAILED' && (<span> Pembayaran gagal</span>)}
            </DrawerHeader>
            <ListTransactionWrapper>
                <DataTable noHeader columns={columns} progressPending={isPending} pagination data={data} />
            </ListTransactionWrapper>
        </Drawer>
    );
};

export default DrawerListTransaction;
