import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Icon from '../Icon';
import iconBulkImage from '../../assets/icon/icon-bulk-image.svg';

const DropWrapper = styled.div`
    position: relative;
    display: block;
    width: 240px;
    text-align: center;

    .dropzone {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        z-index: 1;
        cursor: grab;
    }

    .profile-img {
        position: relative;
        display: flex;
        height: 240px;
        width: 240px;
        background: var(--color-base-bg-light);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #646464;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, .06);
        }

        img {
            width: 100%;
            border-radius: 20px;
        }

    }

    #change-photo {
        position: relative;
        display: block;
        color: var(--color-base-primary-light);
        cursor: pointer;
    }
`;

interface IImageDrop {
    // eslint-disable-next-line no-unused-vars
    onImageSelected: (file: any) => void,
    defaultSrc?: any
}

const ImageDrop: React.FC<IImageDrop> = (props) => {
    const { onImageSelected, defaultSrc } = props;
    const [files, setFiles] = useState<any>([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: { 'image/*': [] },
        multiple: false,
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles: any) => {
            setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        if (files.length > 0) {
            onImageSelected(files[0]);
        }
    }, [files]);

    return (
        <DropWrapper onClick={open}>
            {files.length > 0 && !defaultSrc.src && (
                <div>
                    <div className="profile-img border">
                        <img src={URL.createObjectURL(files[0])} alt={files[0].name} />
                    </div>
                    <p id="change-photo" className="my-2 font-bold">Ganti foto</p>
                    <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                </div>
            )}
            {files.length < 1 && !defaultSrc.src && (
                <div className="profile-img border">
                    <Icon custom icon={iconBulkImage} />
                    <p className="my-2 font-bold" style={{ color: 'var(--color-black)' }}>Foto Profile</p>
                    <p className="my-1 font-small">
                        Drag and drop gambar atau
                        <span className="font-bold" style={{ color: 'var(--color-base-primary-light)' }}> Upload</span>
                    </p>
                    <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                </div>
            )}

            {files.length > 0 && defaultSrc.src && (
                <div>
                    <div className="profile-img">
                        <img src={URL.createObjectURL(files[0])} alt={files[0].name} />
                    </div>
                    <p id="change-photo" className="my-2 font-bold">Ganti foto</p>
                    <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                </div>
            )}
            {files.length < 1 && defaultSrc.src && (
                <div>
                    <div className="profile-img border">
                        <img src={defaultSrc.src} alt="Foto Profile" />
                    </div>
                    <p id="change-photo" className="my-2 font-bold">Ganti foto</p>
                    <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                </div>
            )}
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
            </div>
        </DropWrapper>
    );
};

ImageDrop.defaultProps = {
    defaultSrc: {}
};

export default ImageDrop;
