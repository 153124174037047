/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled.div<{ background?: string, border?: boolean, shadow?: boolean }>`
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    border: ${(props) => (props.border ? '1px solid var(--color-card-border)' : 'none')};
    background: ${(props) => (props.background ? props.background : 'var(--color-white)')};
    box-shadow: ${(props) => (props.shadow ? '0 2px 6px 4px rgba(0, 0, 0, .1)' : 'none')};
    color: var(--color-base-text-light);
`;
