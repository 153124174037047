import React from 'react';
import styled from 'styled-components';
import noDataImg from '../../../assets/img/illustrasi-no-data.png';

const NoDataWrapper = styled.div`
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    height: auto;
    padding-top: 120px;

    img {
        height: 100%;
    }
`;

interface INoData {
    message: string
}

const NoData: React.FC<INoData> = (props) => {
    const { message } = props;
    return (
        <NoDataWrapper>
            <img src={noDataImg} alt="No Data" />
            <p className="mb-0 mt-2 font-bold">{message}</p>
        </NoDataWrapper>
    );
};

export default NoData;
