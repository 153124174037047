/* eslint-disable no-unused-vars */
import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationWrapper = styled.nav<{ marginTop?: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '10px')};

    .paginate {
        position: relative;
        height: 36px;
        width: 100%;
        margin: 0;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        flex-direction: row;
        list-style-type: none;

        .paginate-next,
        .paginate-prev,
        .paginate-page {
            position: relative;
            display: inline-flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            background: var(--color-bg-pagination);
            border: 1px solid var(--color-border-pagination);
            border-radius: 4px;

            &.active {
                background: var(--color-bg-pagination-active);
                border: 1px solid var(--color-border-pagination-active);

                a {
                    color: var(--color-text-pagination-active);
                    outline: none;
                    font-weight: bold;
                }
            }

            a {
                outline: none;
                color: var(--color-text-pagination);
                text-decoration: none;
                width: 100%;
                text-align: center;
            }
        }

        .disabled-arrow {
            background-color: var(--color-arrow-disabled);
            font-weight: bold;
            opacity: 0.5;
        }
    }
`;

const ShowingWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--color-base-text-light);

    p {
        margin-bottom: 0;
    }
`;

interface IPagination {
    totalData: number,
    currentPage: number,
    pageCount: number,
    from?: number,
    to?: number,
    marginTop?: string,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const Pagination: React.FC<IPagination> = (props) => {
    const { totalData, currentPage, pageCount, from, to, marginTop, onPageChange } = props;

    return (
        <PaginationWrapper marginTop={marginTop}>
            <ShowingWrapper>
                <p> Menampilkan </p>
                <p className="mx-1 fw-bold">
                    {from}
                    -
                    {to! > totalData ? totalData : to}
                </p>
                <p> dari </p>
                <p className="mx-1 fw-bold">{totalData}</p>
                <p> data</p>
            </ShowingWrapper>
            <ReactPaginate
                className="paginate"
                pageClassName="paginate-page"
                activeClassName="active"
                breakLabel="..."
                nextClassName="paginate-next"
                nextLabel=">"
                previousLabel="<"
                previousClassName="paginate-prev"
                disabledClassName="disabled-arrow"
                onPageChange={(event: any) => {
                    onPageChange(event);
                }}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                forcePage={currentPage && currentPage !== 0 ? currentPage - 1 : 0}
            />
        </PaginationWrapper>
    );
};

Pagination.defaultProps = {
    from: 1,
    to: 10,
    marginTop: '10px'
};

export default Pagination;
