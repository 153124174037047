import CONSTANT from '../../constant';
import ApiConfig from './config';

const API = {
    login: ApiConfig.POST(CONSTANT.API.LOGIN_URL, false),
    cardInfo: ApiConfig.GET(CONSTANT.API.CARD_INFO, true),
    changePassword: ApiConfig.POST(CONSTANT.API.CHANGE_PASSWORD, true),
    updateProfile: ApiConfig.POST(CONSTANT.API.PROFILE, true),
    myProfile: ApiConfig.GET(CONSTANT.API.PROFILE, true),
    listWorker: ApiConfig.GET(CONSTANT.API.LIST_WORKER, true),
    listTransaction: ApiConfig.GET(CONSTANT.API.LIST_TRANSACTION, true),
    projectActiveRequest: ApiConfig.GET(CONSTANT.API.PROJECT_ACTIVE_REQUEST, true),
    projectContractor: ApiConfig.GET(CONSTANT.API.PROJECT_CONTRACTOR, true),
    changePin: ApiConfig.POST(CONSTANT.API.CHANGE_PIN, true),
    matchPin: ApiConfig.POST(CONSTANT.API.MATCH_PIN, true),
    member: ApiConfig.GET(CONSTANT.API.MEMBER, true),
    shutdownMember: ApiConfig.PUT(CONSTANT.API.MEMBER, true),
    createMember: ApiConfig.POST(CONSTANT.API.CREATE_MEMBER, true),
    deleteMember: ApiConfig.DELETE(CONSTANT.API.DELETE_MEMBER, true),
    memberDetail: ApiConfig.GET(CONSTANT.API.MEMBER_DETAIL, true),
    recoverBlacklist: ApiConfig.POST(CONSTANT.API.RECOVER_BLACKLIST, true),
    blacklist: ApiConfig.GET(CONSTANT.API.BLACKLIST, true),
    paymentStatus: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS, true),
    paymentStatusPending: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_PENDING, true),
    paymentStatusPendingDetail: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_PENDING_DETAIL, true),
    paymentStatusWaiting: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_WAITING, true),
    paymentStatusWaitingDetail: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_WAITING_DETAIL, true),
    paymentStatusInProgress: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_IN_PROGRESS, true),
    paymentStatusInProgressDetail: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_IN_PROGRES_DETAIL, true),
    paymentStatusFailed: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_FAILED, true),
    paymentStatusSuccess: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_SUCCESS, true),
    paymentStatusSuccessDetail: ApiConfig.GET(CONSTANT.API.PAYMENT_STATUS_SUCCESS_DETAIL, true),
    acceptPayment: ApiConfig.POST(CONSTANT.API.ACCEPT_PAYMENT, true),
    rejectPayment: ApiConfig.POST(CONSTANT.API.REJECT_PAYMENT, true),
    inProgressPay: ApiConfig.POST(CONSTANT.API.IN_PROGRESS_PAY, true),
    inProgressCancel: ApiConfig.POST(CONSTANT.API.IN_PROGRESS_CANCEL, true),
    feeAdmin: ApiConfig.GET(CONSTANT.API.FEE_ADMIN, true),
    log: ApiConfig.GET(CONSTANT.API.LOG, true),
    checkAccountBank: ApiConfig.POST(CONSTANT.API.CHECK_ACCOUNT_BANK, true),
    reportPayment: ApiConfig.GET(CONSTANT.API.REPORT_PAYMENT, true),
    listProject: ApiConfig.GET(CONSTANT.API.LIST_PROJECT, true)
};

export default API;
