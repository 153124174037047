import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TabLayout = styled.div<{sticky?: boolean, top?: number}>`
    position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
    display: flex;
    flex-direction: row;
    background: var(--color-base-bg-light);
    top: ${(props) => (props.top ? `${props.top}px` : '0')};
    z-index: 3;
`;

export const TabItem = styled.div`
    position: relative;
    display: block;
    width: auto;
    padding: 10px 5px;
    margin: 0 10px;
    text-decoration: none;
    color: var(--color-base-text-light);
    cursor: pointer;

    &.active {
        font-weight: var(--font-bold);
        border-bottom: 2px solid var(--color-base-primary-light);
    }

    &:hover, &:focus {
        color: var(--color-base-text-light);
        outline: none;
    }
    
`;

export const TabItemLink = styled(Link)`
    position: relative;
    display: block;
    width: auto;
    padding: 10px 5px;
    margin: 0 10px;
    text-decoration: none;
    color: var(--color-base-text-light);
    cursor: pointer;

    &.active {
        font-weight: var(--font-bold);
        border-bottom: 2px solid var(--color-base-primary-light);
    }

    &:hover, &:focus {
        color: var(--color-base-text-light);
        outline: none;
    }
    
`;

export const TabContent = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 0;
`;
