import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducer/auth';
import uiReducer from '../reducer/ui';
import userReducer from '../reducer/user';

const store = configureStore({
    reducer: {
        authReducer,
        uiReducer,
        userReducer
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
