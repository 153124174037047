import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { ButtonIcon } from '../../../components/Styled';
import iconCheck from '../../../assets/icon/icon-check.svg';
import iconDocument from '../../../assets/icon/icon-document-circle.svg';
import iconTrash from '../../../assets/icon/icon-trash-red.svg';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';
import DialogCancelPayment from './DialogCancelPayment';
import DialogProcessPayment from './DialogProcessPayment';
import API from '../../../configs/api';
import DrawerCancelPayment from './DrawerCancelPayment';
import DrawerPaymentDetail from './DrawerPaymentDetail';

const RowWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const PopUpActionWrapper = styled.div`
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px;
    background: var(--color-base-bg-light);
    top: 0;
    right: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
    z-index: 2;
    
    #action-item {
        position: relative;
        display: flex;
        padding: 10px;
        width: 100%;
        margin: 2px 0;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        background: var(--color-base-bg-light);
        align-items: center;

        &:hover {
            border-radius: 10px;
            background: rgba(0, 0, 0, .05);
        }

        p {
            text-align: left;
            margin: 0;
        }
    }
`;

interface IRowProccessAction {
    data: any
}

const RowProccessAction: React.FC<IRowProccessAction> = (props) => {
    const { data } = props;
    const dispatch = useAppDispatch();
    const [showAction, setShowAction] = useState(false);
    const [dialogCancel, setDialogCancel] = useState(false);
    const [dialogProcess, setDialogProcess] = useState(false);
    const [drawerCancel, setDrawerCancel] = useState(false);
    const [drawerDetail, setDrawerDetail] = useState(false);
    // const onDetailClick = (id: any) => {
    //     dispatch(action.drawerProfile(id, true));
    // };

    const onCancelConfim = () => {
        setDrawerCancel(true);
        // const payload = {
        //     body: {
        //         external_id: data.external_id,
        //         external_id: data.external_id,
        //         external_id: data.external_id,
        //         external_id: data.external_id,
        //         external_id: data.external_id
        //     }
        // };
    };

    const onProcessConfim = () => {
        dispatch(action.showLoading());
        const payload = {
            body: {
                external_id: data.external_id
            }
        };
        API.inProgressPay(payload).then(() => {
            dispatch(action.showToast('success', 'Pembayaran berhasil di proses'));
        }).catch(() => {
            dispatch(action.showToast('error', 'Pembayaran gagal di proses'));
            dispatch(action.hideLoading());
        });
    };

    return (
        <>
            <RowWrapper onMouseLeave={() => setShowAction(false)}>
                <ButtonIcon onClick={() => setShowAction(true)}>
                    <Icon icon="more-vertical" />
                </ButtonIcon>
                {showAction && (
                    <PopUpActionWrapper>
                        <div role="button" id="action-item" onClick={() => setDialogProcess(true)}>
                            <Icon custom icon={iconCheck} />
                            <p>Selesaikan Sekarang</p>
                        </div>
                        <div className="lines" />
                        <div role="button" id="action-item" onClick={() => setDrawerDetail(true)}>
                            <Icon custom icon={iconDocument} />
                            <p>Lihat Detail Pembayaran</p>
                        </div>
                        <div className="lines" />
                        <div role="button" id="action-item" onClick={() => setDialogCancel(true)}>
                            <Icon custom icon={iconTrash} />
                            <p>Batalkan Pembayaran</p>
                        </div>
                    </PopUpActionWrapper>
                )}
            </RowWrapper>
            {dialogCancel && (
                <DialogCancelPayment onCancelConfim={() => onCancelConfim()} dialogHandler={(showDialog: boolean) => setDialogCancel(showDialog)} />
            )}
            {dialogProcess && (
                <DialogProcessPayment onProcessConfim={() => onProcessConfim()} dialogHandler={(showDialog: boolean) => setDialogProcess(showDialog)} />
            )}
            {drawerCancel && (
                <DrawerCancelPayment data={data} drawerHandler={(showDrawer: boolean) => setDrawerCancel(showDrawer)} />
            )}
            {drawerDetail && (
                <DrawerPaymentDetail data={data} drawerHandler={(showDrawer: boolean) => setDrawerDetail(showDrawer)} />
            )}
        </>
    );
};

export default RowProccessAction;
