/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useDate from '../../helpers/useDate';
import Pagination from '../Pagination';
import { ActivityWrapper, DateToday } from './ListActivity';
import type { IListActivity } from './ListActivity';

interface IData {
    start_project_date: string,
    end_project_date: string,
    project_name: string,
    kontraktor: string,
    client_name: string,
    role: string
}

interface IItemExperience {
    data: IData
}

const ItemExperience: React.FC<IItemExperience> = ({ data }) => {
    const { start_project_date, end_project_date, project_name, kontraktor, client_name, role } = data;

    return (
        <div className="py-4 color-text" style={{ borderBottom: '1px solid #D8CFCD' }}>
            <div className="row mx-cs-2">
                <div className="col-3 ps-0">
                    <p className="mb-1 font-small">Tanggal Bekerja</p>
                    <p className="fw-bold mb-0">{`${useDate.getMomentDate(start_project_date, false, 'DD MMMM YYYY')} ${end_project_date ? ` - ${end_project_date === 'Sekarang' ? end_project_date : useDate.getMomentDate(end_project_date, false, 'DD MMMM YYYY')}` : ''}`}</p>
                </div>
                <div className="col-3">
                    <p className="mb-1 font-small">Nama Proyek</p>
                    <p className="fw-bold mb-0">{project_name || '-'}</p>
                </div>
                <div className="col-2">
                    <p className="mb-1 font-small">Kontraktor</p>
                    <p className="fw-bold mb-0">{kontraktor || '-'}</p>
                </div>
                <div className="col-2">
                    <p className="mb-1 font-small">Client</p>
                    <p className="fw-bold mb-0">{client_name || '-'}</p>
                </div>
                <div className="col-2">
                    <p className="mb-1 font-small">Tugas</p>
                    <p className="fw-bold mb-0">{role || '-'}</p>
                </div>
            </div>
        </div>
    );
};

const ListExperience: React.FC<IListActivity> = (props) => {
    const { data, page, from, to, onPageChange } = props;
    return (
        <ActivityWrapper>
            <DateToday />
            {data.total_data > 0 && data.data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ItemExperience key={index} data={item} />
            ))}
            <div className="mx-cs-2">
                <Pagination
                    from={from}
                    to={to}
                    totalData={data.total_data}
                    currentPage={page}
                    pageCount={data.total_page}
                    onPageChange={onPageChange}
                />
            </div>
        </ActivityWrapper>
    );
};

export default ListExperience;
