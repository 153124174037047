import React from 'react';
import { TabItemLink, TabLayout } from '../../../components/Styled';

interface ITabLink {
    tabActive: string
}

const TabLink: React.FC<ITabLink> = (props) => {
    const { tabActive } = props;
    const tabs = [
        { id: 1, title: 'Daftar Blacklist', name: 'list' },
        { id: 2, title: 'Riwayat Blacklist', name: 'riwayat' }
    ];

    return (
        <TabLayout sticky top={60}>
            {tabs.map((tab: any) => (
                <TabItemLink key={tab.id} to={`/blacklist/${tab.name}`} className={tabActive === tab.name ? 'active' : ''}>{tab.title}</TabItemLink>
            ))}
        </TabLayout>
    );
};

export default TabLink;
