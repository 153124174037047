/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Badge = styled.div<{ block?: boolean, background?: string, rounded?: boolean, transparent?: boolean, success?: boolean, error?: boolean, warning?: boolean, red?: boolean }>`
    position: relative;
    display: ${(props) => (props.block ? 'block' : 'inline-block')};
    width: auto;
    padding: 3px 20px;
    font-size: var(--font-small);
    background: ${(props) => (props.background ? props.background : '#fff')};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.background ? `darken(${props.background}, 10%)` : '#D8CFCD')};
    border-radius: ${(props) => (props.rounded ? '100px' : '6px')};
    
    ${(props) => (props.transparent ? 'background: transparent;' : '')}

    ${(props) => (props.red ? `
        background: #D9272D;
        color: #ffffff;
        border-color: #D9272D;
    ` : '')}

    ${(props) => (props.success ? `
        background: #E5FFE7;
        color: #00840C;
        border-color: #89F093;
    ` : '')}

    ${(props) => (props.warning ? `
        background: #FED04929;
        color: #847800;
        border-color: #FED049;
    ` : '')}

    ${(props) => (props.error ? `
        background: #FFF4F5;
        color: #D9272D;
        border-color: #F2B7B9;
    ` : '')}
`;
