import CONSTANT from '../../../../constant';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import API from '../../../api';
import { setLoading } from '../../reducer/ui';
import { setUser } from '../../reducer/user';

const getProfile = () => (dispatch: any) => {
    API.myProfile().then((result: any) => {
        useLocalStorage.set(CONSTANT.APP.USER_PROFILE, result.data);
        dispatch(setUser(result.data));
        dispatch(setLoading(false));
        // console.log(result);
    });
};

const updateProfile = () => {
};

export {
    getProfile,
    updateProfile
};
