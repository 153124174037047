import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../hooks/useAppReducer';
import Dialog from './Dialog';
import NumberKeyboard from './NumberKeyboard';
import { Button, TextInput } from './Styled';
import logoPayment from '../assets/img/payment-logo.png';
import useCurrency from '../helpers/useCurrency';
import Icon from './Icon';
import iconProfile from '../assets/icon/icon-profile-circle.svg';

const SubtotalWrapper = styled.div`
    position: relative;
    display: block;
    padding: 25px 0;
    background: #2F2F2F;
    border-radius: 20px;
    color: var(--color-white);
`;

const PinWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px 30px;
    border-radius: 20px;
    background: var(--color-white);
    box-shadow: 0px 4px 250px #EFEFEF;

    input {
        text-align: center;
    }

    #number-keyboard {
        margin: 0 auto;
    }
`;

interface IMatchingPinDialog {
    fee: number,
    total: number,
    // eslint-disable-next-line no-unused-vars
    onConfirm: (value: string) => void,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (showDialog: boolean) => void
}

const MatchingPinDialog: React.FC<IMatchingPinDialog> = (props) => {
    const { fee, total, onConfirm, dialogHandler } = props;
    const profile = useAppSelector((state: any) => state.userReducer.profile);
    const [pin, setPin] = useState('');

    const handlePinChange = (value: string) => {
        if (value.length < 7) {
            setPin(value);
        }
    };

    const handleSubmit = () => {
        onConfirm(pin);
        setTimeout(() => {
            dialogHandler(false);
        }, 200);
    };
    return (
        <Dialog fullWidth dialogHandler={dialogHandler}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-6">
                        <div className="container text-center">
                            <img src={logoPayment} alt="Payment" />
                            <p className="mt-3 mb-0 font-bold">Permintaan Pembayaran</p>
                            <p className="mb-4">Pastikan kamu sudah mengecek seluruh permintaan pembayaran</p>
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <SubtotalWrapper>
                                        <p className="m-0">Total yang dibayarkan</p>
                                        <p className="m-0 font-bold">{`Rp${useCurrency(total)}`}</p>
                                        <span className="mt-2 d-block font-extrasmall">{`*Rp. ${useCurrency(fee)} Biaya admin pembayaran`}</span>
                                    </SubtotalWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <PinWrapper>
                            <div className="row align-items-center mb-3">
                                <div className="col-profile">
                                    <div className="profile-img">
                                        {profile.profile_image && (
                                            <img src={profile.profile_image} alt={profile.name} />
                                        )}
                                        {!profile.profile_image && (
                                            <Icon custom icon={iconProfile} />
                                        )}
                                    </div>
                                </div>
                                <div className="col-8">
                                    <p className="m-0 font-bold">PIN</p>
                                    <span className="m-0 font-small">Silahkan masukkan PIN kamu</span>
                                </div>
                            </div>
                            <TextInput className="mb-4" type="password" block border value={pin} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handlePinChange(event.target.value)} />
                            <NumberKeyboard value={pin} onClick={(value: string) => handlePinChange(value)} />
                            <div className="row mt-3">
                                <div className="col-6">
                                    <Button block rounded border primary bold onClick={() => dialogHandler(false)}>Batal</Button>
                                </div>
                                <div className="col-6">
                                    <Button block rounded gradient bold onClick={() => handleSubmit()}>Konfirmasi</Button>
                                </div>
                            </div>
                        </PinWrapper>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default MatchingPinDialog;
