import React from 'react';
import loading from '../assets/gif/loading.gif';

const LoadingOverlay = () => {
    return (
        <>
            <div id="overlay" style={{ zIndex: 199 }} />
            <div className="loading-wrapper">
                <div className="loading-anim">
                    <img src={loading} alt="loading" />
                </div>
                <span>Mohon Menunggu</span>
            </div>
        </>

    );
};

export default LoadingOverlay;
