import { useEffect, useState } from 'react';
import API from '../configs/api';

const useListProject = () => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        const handleData = (value: any) => {
            setData(value);
        };

        API.listProject().then((result: any) => {
            handleData(result.data);
        });

        return () => {
            handleData({
                card: {},
                list: []
            });
        };
    }, []);

    return data;
};

export default useListProject;
