/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import { TextInput } from '../Styled';

const SelectWrapper = styled.div`
    position: relative;
    display: block;
    height: 48px;
    width: 100%;
    margin: 0;

    #placeholder {
        color: #A5A5A6;
    }
`;

const SelectInputWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    background: var(--color-input);
    padding: 6px 1rem;
    margin: 0;
    font-size: var(--font-normal);
    color: var(--color-base-text-light);
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--color-card-border);
    cursor: pointer;

    #toggle-icon {
        position: absolute;
        width: auto;
        height: auto;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }
`;

const OptionWrapper = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    max-height: 400px;
    padding: 10px 20px 0;
    background: var(--color-input);
    border-radius: 10px;
    box-shadow: 0px 3.12956px 12.5182px rgba(146, 146, 146, 0.25);
    top: 60px;
    left: 0;
    user-select: none;
    overflow: auto;
    z-index: 100;
`;

const OptionHeader = styled.div`
    position: sticky;
    display: flex;
    width: center;
    flex-direction: row;
    background: var(--color-input);
    align-items: center;
    padding: 20px 0;
    margin: 0 0 10px;
    top: 0;
    border-bottom: 1px dashed #2F2F2F;
    z-index: 1;

    #icon {
        position: relative;
        display: inline-block;
        width: auto;
        margin-right: 10px;
        img {
            width: 100%;
        }
    }

    ${TextInput} {
        width: 100%;
    }
`;

const OptionItem = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 0 15px;
    margin: 0 0 10px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #D8CFCD;
    cursor: pointer;

    #icon {
        position: relative;
        display: inline-block;
        width: auto;
        margin-right: 10px;
        img {
            width: 100%;
        }
    }

    #desc {
        position: relative;
        display: block;
        color: var(--color-base-text-light);
        width: 100%;
    }

    span {
        color: #A5A5A6;
    }

    &:last-child {
        border: none;
    }

    &:hover {
        background: var(--color-semi-white);
    }
`;

interface ISelectInput {
    options: any[],
    value?: string,
    placeholder?: string,
    optionHeader?: boolean,
    headerPlaceholder?: string,
    optionIcon?: any,
    className?: any,
    style?: React.CSSProperties,
    // eslint-disable-next-line no-unused-vars
    onOptionSelected?: (value: string) => void
}

const SelectInput: React.FC<ISelectInput> = (props) => {
    const { options, style, className, optionHeader, headerPlaceholder, optionIcon, value, placeholder, onOptionSelected } = props;
    const [data, setData] = useState<any>([]);
    const [isOpen, setOpen] = useState(false);
    const [filterName, setFilterName] = useState('');

    const handleOptionSelect = (selectedItem: any) => {
        if (onOptionSelected) {
            onOptionSelected(selectedItem);
        }
        setOpen(false);
    };

    useEffect(() => {
        if (filterName.length > 0) {
            const matches = options.filter((match: any) => {
                const regex = new RegExp(`${filterName}`, 'gi');
                return match.title.match(regex);
            });
            setData(matches);
            return;
        }
        setData(options);
    }, [filterName]);

    return (
        <SelectWrapper style={style} className={className}>
            <SelectInputWrapper onClick={() => setOpen(!isOpen)}>
                {!value && (
                    <span id="placeholder">{placeholder}</span>
                )}
                {value && (
                    <span style={{ color: 'inherit' }} className="font-bold">{value}</span>
                )}
                <div id="toggle-icon">
                    <Icon icon="chevron-down" />
                </div>
            </SelectInputWrapper>
            {isOpen && (
                <>
                    <div id="overlay" onClick={() => setOpen(false)} className="transparent" />
                    <OptionWrapper>
                        {optionHeader && (
                            <OptionHeader>
                                {optionIcon && (
                                    <div id="icon">
                                        <img src={optionIcon} alt="Icon Selector" />
                                    </div>
                                )}
                                <TextInput placeholder={headerPlaceholder} block border onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterName(event.target.value)} value={filterName} />
                            </OptionHeader>
                        )}
                        {data.map((item: any, idx: any) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <OptionItem key={idx} onClick={() => handleOptionSelect(item)}>
                                <div id="icon">
                                    {item?.icon && (
                                        <img src={item.icon} alt="Icon" />
                                    )}
                                </div>
                                <div id="desc">
                                    {item.value && (
                                        <p className="m-0 font-bold font-small">
                                            {item.title}
                                            {item?.note && (
                                                <span className="fw-normal ms-2">{item.note}</span>
                                            )}
                                        </p>
                                    )}
                                    {item?.description && (
                                        <span className="font-small">{item.description}</span>
                                    )}
                                </div>
                            </OptionItem>
                        ))}
                    </OptionWrapper>
                </>
            )}
        </SelectWrapper>
    );
};

SelectInput.defaultProps = {
    value: '',
    placeholder: '',
    style: undefined,
    className: undefined,
    optionHeader: false,
    headerPlaceholder: '',
    optionIcon: '',
    onOptionSelected: () => { }
};

export default SelectInput;
