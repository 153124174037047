import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const ToastWrapper = styled.div<{ visible?: boolean }>`
    position: fixed;
    width: auto;
    padding: 14px 16px;
    color: var(--color-white);
    font-weight: 400;
    border-radius: 20px;
    top: 2rem;
    transform: translateY(-50%);
    transition: .3s ease;
    z-index: 109;

    &.left {
        left: 2rem;
        ${(props) => (props.visible ? 'left: 2rem;' : '')};
    }
    &.right {
        right: -120%;
        ${(props) => (props.visible ? 'right: 2rem;' : '')};
    }
    
    &.success {
        background: #4BD37B;
    }

    &.error {
        background: #D34B4B;
    }
`;

interface IToast {
    id?: string,
    message: string,
    left?: boolean,
    right?: boolean,
    toastType: string
}

const Toast = (props: IToast) => {
    const { id, message, left, right, toastType } = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 250);
    }, []);

    return (
        <ToastWrapper id={id} className={`${left ? 'left' : ''}${right ? 'right' : ''} ${toastType || ''}`} visible={visible}>
            {visible && toastType === 'success' && (<Icon icon="check" />)}
            {visible && toastType === 'error' && (<Icon icon="x" />)}
            <span className="font-bold m-2">{message}</span>
        </ToastWrapper>
    );
};

Toast.defaultProps = {
    id: '',
    left: false,
    right: false
};

export default Toast;
