import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '../../../components/Drawer';
import { Button, Label, TextInput, TextInputGroup } from '../../../components/Styled';
import useCurrency from '../../../helpers/useCurrency';
import iconAlert from '../../../assets/icon/icon-alert.svg';
import Icon from '../../../components/Icon';
import SelectInput from '../../../components/SelectInput';
import API from '../../../configs/api';

// const DrawerHeader = styled.div`
//     position: sticky;
//     display: block;
//     width: 100%;
//     height: auto;
//     top: 0;
//     padding: 1.5rem;
//     // background: #F7F5F5;
//     z-index: 1;

//     button {
//         background: #F7F5F5;
//         border-color: #D8CFCD;
//     }
// `;

const DrawerBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: .75rem 0 3rem;

    #item {
        position: relative;
        padding: 0 1.5rem;
    }
`;

const NotifWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding 15px 20px;
    border-radius: 20px;
    background: #FFF4F5;
    gap: 20px;
`;

interface IDrawerCancelPayment {
    data: any
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

const bankOptions = [
    {
        title: 'BCA',
        description: 'Bank Central Asia',
        length: 10,
        value: 'BCA'
    },
    {
        title: 'BNI',
        description: 'Bank Negara Indonesia',
        length: 10,
        value: 'BNI'
    },
    {
        title: 'BRI',
        description: 'Bank Rakyat Indonesia',
        length: 15,
        value: 'BRI'
    },
    {
        title: 'BANK MANDIRI',
        description: 'Bank Mandiri',
        length: 13,
        value: 'MANDIRI'
    }
];

const DrawerCancelPayment: React.FC<IDrawerCancelPayment> = (props) => {
    const { data, drawerHandler } = props;
    const [bank, setBank] = useState('');
    const [selectedBank, setSelectedBank] = useState<any>({});
    const [account_name, setAccountName] = useState('');
    const [account_number, setAccountNumber] = useState('');
    // const [saveInfo, setSaveInfo] = useState(false);

    useEffect(() => {
        setBank(selectedBank.title);
        setAccountName('');
    }, [selectedBank]);

    const onSubmit = () => {
        const payload = {
            body: {
                external_id: data.external_id,
                refund_amount: data.amount,
                account_number,
                account_name,
                bank_code: selectedBank.VALUE
            }
        };

        API.acceptPayment(payload);
    };

    const handleCheckBank = () => {
        const payload = {
            body: { account_number, bank_code: selectedBank.value }
        };
        API.checkAccountBank(payload).then((result: any) => {
            setAccountName(result.data.name);
        });
    };

    useEffect(() => {
        if (account_number.length >= selectedBank.length) {
            handleCheckBank();
        }
    }, [account_number]);

    return (
        <Drawer noPadding drawerHandler={drawerHandler} right>
            <DrawerBody>
                <div className="row mx-3 mt-3 mb-4">
                    <div className="col-12 mb-4">
                        <p className="m-0 font-bold">Batalkan Pembayaran</p>
                        <span>Pembayaran yang sudah ditransfer akan dikembalikan ke rekening</span>
                    </div>
                    <div className="col-7">
                        <Label bold htmlFor="nominal">Jumlah yang dikembalikan</Label>
                        <TextInputGroup style={{ marginBottom: 5 }} border>
                            <TextInput block id="nominal" type="text" placeholder={`Rp ${useCurrency(data.amount)}`} disabled />
                        </TextInputGroup>
                    </div>
                </div>
                <div className="lines" />
                <div className="row mx-3 mt-4">
                    <div className="col-12 mb-3">
                        <p className="m-0 font-bold">Informasi Bank</p>
                    </div>
                    <div className="col-12 mb-4">
                        <NotifWrapper>
                            <Icon custom icon={iconAlert} />
                            <span>Mohon pastikan nama dan nomor rekening sudah benar sama dengan yang terdaftar, termasuk spasi dan nama depan atau tengah</span>
                        </NotifWrapper>
                    </div>
                    <div className="col-7 mb-4">
                        <Label bold>Pilih Bank</Label>
                        <SelectInput options={bankOptions} value={bank} onOptionSelected={(item: any) => setSelectedBank(item)} placeholder="Pilih bank" />
                    </div>
                    <div className="col-7 mb-4">
                        <Label bold htmlFor="nominal">Nomor Rekening</Label>
                        <TextInput block border id="nominal" type="text" placeholder="Masukkan nomor rekening" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountNumber(e.target.value)} value={account_number} />
                    </div>
                    <div className="col-7 mb-4">
                        <Label bold htmlFor="nominal">Nama Penerima</Label>
                        <TextInput block border id="nominal" type="text" placeholder="Nama akan terisi otomatis" value={account_name} disabled />
                    </div>
                </div>
                <div className="row align-items-center mx-3">
                    <div className="col-7" />
                    <div className="col-5 text-end">
                        <Button style={{ marginRight: 10 }} border rounded textBlack>Batalkan</Button>
                        <Button gradient rounded onClick={() => onSubmit()}>
                            Lanjutkan
                            <Icon icon="arrow-right" />
                        </Button>
                    </div>
                </div>
            </DrawerBody>
        </Drawer>
    );
};

export default DrawerCancelPayment;
