import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import useDate from '../../../helpers/useDate';
import { Card } from '../../../components/Styled';
import iconCheck from '../../../assets/icon/icon-check-circle.svg';
import iconWait from '../../../assets/icon/icon-wait-circle.svg';
import iconX from '../../../assets/icon/icon-x-circle.svg';
import useCurrency from '../../../helpers/useCurrency';
import DrawerListTransaction from './DrawerListTransaction';
import API from '../../../configs/api';
import DialogWorker from './DialogWorker';
import Shimmer from '../../../components/Shimmer';

const MoreLink = styled(Link) <{ textWhite?: boolean }>`
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: ${(props) => (props.textWhite ? 'var(--color-white)' : 'var(--color-base-text-light);')}
    transform: translateX(0);
    transition: transform .2s ease;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: var(--font-small);
    }

    .feather {
        color: ${(props) => (props.textWhite ? 'var(--color-white)' : 'var(--color-base-primary-light);')}
    }

    &:hover {
        transform: translateX(4px);
    }
`;

const MoreBtn = styled.div<{ textWhite?: boolean }>`
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: ${(props) => (props.textWhite ? 'var(--color-white)' : 'var(--color-base-text-light);')}
    transform: translateX(0);
    transition: transform .2s ease;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: var(--font-small);
    }

    .feather {
        color: ${(props) => (props.textWhite ? 'var(--color-white)' : 'var(--color-base-primary-light);')}
    }

    &:hover {
        transform: translateX(4px);
    }
`;

const CardInformation = () => {
    const [data, setData] = useState<any>([]);
    const [drawerListTransaction, setDrawerListTransaction] = useState(false);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [selectedWorkerRole, setSelectedWorkerRole] = useState('');
    const [dialogWorker, setDialogWorker] = useState(false);
    const [isPending, setPending] = useState(true);

    const fetchData = () => {
        API.cardInfo().then((result: any) => {
            setData(result.data);
            setTimeout(() => {
                setPending(false);
            }, 1500);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onPaymentMoreClick = (status: string) => {
        setSelectedPaymentStatus(status);
        setDrawerListTransaction(true);
    };

    const onWorkerMoreClick = (role: string) => {
        setSelectedWorkerRole(role);
        setDialogWorker(true);
    };

    return (
        <>
            <div className="row justify-content-between mb-3">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <Card background="linear-gradient(90deg, #B993D6 0%, #8CA6DB 100%)" style={{ color: '#fff' }}>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Icon custom icon={iconCheck} />
                            </div>
                            <div className="col-6 text-end mb-1">
                                {!isPending && (
                                    <>
                                        <span className="font-extrasmall">Bulan ini</span>
                                        <p className="m-0 font-small">{useDate.getMonth()}</p>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="mb-2" style={{ height: 10, width: 70, float: 'right' }} type="line" />
                                        <Shimmer style={{ height: 10, width: 100, float: 'right' }} type="line" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="my-2 me-1 d-inline-block">
                                            Total Sudah Dibayarkan
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="my-2 me-1" style={{ width: 160 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <span style={{ position: 'absolute' }}>Rp</span>
                                        <h4 className="d-inline-block mb-0 font-bold" style={{ marginLeft: 20 }}>{useCurrency(data.total_paid)}</h4>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="d-inline-block me-1" style={{ position: 'absolute', width: 15, borderRadius: 5 }} type="line" />
                                        <Shimmer className="d-inline-block" style={{ height: 30, width: 30, marginLeft: 20, borderRadius: 5 }} type="line" />
                                    </>
                                )}
                            </div>
                            <div className="col-12">
                                <div className="lines l-white mt-3 mb-2" />
                            </div>
                            <div className="col-12 mb-2 text-end">
                                <MoreBtn textWhite onClick={() => onPaymentMoreClick('PAID')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <Card background="linear-gradient(90deg, #74EBD5 0%, #ACB6E5 100%)" style={{ color: '#fff' }}>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Icon custom icon={iconWait} />
                            </div>
                            <div className="col-6 text-end mb-1">
                                {!isPending && (
                                    <>
                                        <span className="font-extrasmall">Bulan ini</span>
                                        <p className="m-0 font-small">{useDate.getMonth()}</p>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="mb-2" style={{ height: 10, width: 70, float: 'right' }} type="line" />
                                        <Shimmer style={{ height: 10, width: 100, float: 'right' }} type="line" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="my-2 me-1 d-inline-block">
                                            Total Pembayaran Pending
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="my-2 me-1" style={{ width: 160 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <span style={{ position: 'absolute' }}>Rp</span>
                                        <h4 className="d-inline-block mb-0 font-bold" style={{ marginLeft: 20 }}>{useCurrency(data.total_pending)}</h4>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="d-inline-block me-1" style={{ position: 'absolute', width: 15, borderRadius: 5 }} type="line" />
                                        <Shimmer className="d-inline-block" style={{ height: 30, width: 30, marginLeft: 20, borderRadius: 5 }} type="line" />
                                    </>
                                )}
                            </div>
                            <div className="col-12">
                                <div className="lines l-white mt-3 mb-2" />
                            </div>
                            <div className="col-12 mb-2 text-end">
                                <MoreBtn textWhite onClick={() => onPaymentMoreClick('PENDING')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="col-12 col-md-4">
                    <Card background="linear-gradient(90deg, #F2709C 0%, #FF9472 100%)" style={{ color: '#fff' }}>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Icon custom icon={iconX} />
                            </div>
                            <div className="col-6 text-end mb-1">
                                {!isPending && (
                                    <>
                                        <span className="font-extrasmall">Bulan ini</span>
                                        <p className="m-0 font-small">{useDate.getMonth()}</p>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="mb-2" style={{ height: 10, width: 70, float: 'right' }} type="line" />
                                        <Shimmer style={{ height: 10, width: 100, float: 'right' }} type="line" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="my-2 me-1 d-inline-block">
                                            Total Pembayaran Gagal
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="my-2 me-1" style={{ width: 160 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <span style={{ position: 'absolute' }}>Rp</span>
                                        <h4 className="d-inline-block mb-0 font-bold" style={{ marginLeft: 20 }}>{useCurrency(data.total_failed)}</h4>
                                    </>
                                )}
                                {isPending && (
                                    <>
                                        <Shimmer className="d-inline-block me-1" style={{ position: 'absolute', width: 15, borderRadius: 5 }} type="line" />
                                        <Shimmer className="d-inline-block" style={{ height: 30, width: 30, marginLeft: 20, borderRadius: 5 }} type="line" />
                                    </>
                                )}
                            </div>
                            <div className="col-12">
                                <div className="lines l-white mt-3 mb-2" />
                            </div>
                            <div className="col-12 mb-1 text-end">
                                <MoreBtn textWhite onClick={() => onPaymentMoreClick('FAILED')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row justify-content-between">
                <div className="col-6 col-md-3 mb-3 mb-md-0">
                    <Card border>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="mb-2 me-1 d-inline-block">
                                            Jumlah Proyek Aktif
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ width: 100 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <h4 className="d-inline-block m-0 font-bold">{data.total_project_active}</h4>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ height: 30, width: 30 }} type="line" />
                                )}
                            </div>
                            <div className="col-12 my-2 text-end">
                                <MoreLink to="/proyek-kontraktor">
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreLink>
                            </div>
                        </div>
                        <div className="lines l-white l-bold l-rounded my-2" style={{ background: '#F7D4D5' }} />
                    </Card>
                </div>

                <div className="col-6 col-md-3 mb-3 mb-md-0">
                    <Card border>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="mb-2 me-1 d-inline-block">
                                            Jumlah PM Aktif
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ width: 100 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <h4 className="d-inline-block m-0 font-bold">{data.total_pm_active}</h4>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ height: 30, width: 30 }} type="line" />
                                )}
                            </div>
                            <div className="col-12 my-2 text-end">
                                <MoreBtn onClick={() => onWorkerMoreClick('PM')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                        <div className="lines l-white l-bold l-rounded my-2" style={{ background: '#EED4F7' }} />
                    </Card>
                </div>

                <div className="col-6 col-md-3 mb-3 mb-md-0">
                    <Card border>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="mb-2 me-1 d-inline-block">
                                            Jumlah Pelaksana/Logistik
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ width: 100 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <h4 className="d-inline-block m-0 font-bold">{data.total_pelaksana_active}</h4>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ height: 30, width: 30 }} type="line" />
                                )}
                            </div>
                            <div className="col-12 my-2 text-end">
                                <MoreBtn onClick={() => onWorkerMoreClick('PELAKSANA')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                        <div className="lines l-white l-bold l-rounded my-2" style={{ background: '#D4E4F7' }} />
                    </Card>
                </div>

                <div className="col-6 col-md-3">
                    <Card border>
                        <div className="row">
                            <div className="col-12">
                                {!isPending && (
                                    <>
                                        <p className="mb-2 me-1 d-inline-block">
                                            Jumlah Mandor Aktif
                                        </p>
                                        <Icon height="12" width="12" icon="info" />
                                    </>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ width: 100 }} type="line" />
                                )}
                            </div>
                            <div className="col-12">
                                {!isPending && (
                                    <h4 className="d-inline-block m-0 font-bold">{data.total_mandor_active}</h4>
                                )}
                                {isPending && (
                                    <Shimmer className="d-inline-block dark" style={{ height: 30, width: 30 }} type="line" />
                                )}
                            </div>
                            <div className="col-12 my-2 text-end">
                                <MoreBtn onClick={() => onWorkerMoreClick('MANDOR')}>
                                    <span>Selengkapnya</span>
                                    <Icon icon="arrow-right" />
                                </MoreBtn>
                            </div>
                        </div>
                        <div className="lines l-white l-bold l-rounded my-2" style={{ background: '#D4F7E4' }} />
                    </Card>
                </div>
            </div>
            {dialogWorker && (
                <DialogWorker role={selectedWorkerRole} dialogHandler={(showDialog: boolean) => setDialogWorker(showDialog)} />
            )}
            {drawerListTransaction && (
                <DrawerListTransaction status={selectedPaymentStatus} drawerHandler={(showDrawer: boolean) => setDrawerListTransaction(showDrawer)} />
            )}
        </>
    );
};

export default CardInformation;
