import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import iconWhatsapp from '../../../assets/icon/icon-whatsapp.svg';
import iconEmail from '../../../assets/icon/icon-email-send.svg';
import iconCall from '../../../assets/icon/icon-call.svg';
import { Card } from '../../../components/Styled';

const HelpWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 25px;
    height: auto;
    width: 100%;
    max-width: 320px;
    padding: 1rem;
    background: var(--color-white);
    box-shadow: 0 2px 6px 4px rgba(0, 0, 0, .1);
    border-radius: 10px;
    z-index: 1;
`;

const Help: React.FC = () => {
    return (
        <HelpWrapper>
            <div className="row my-3">
                <div className="col-12">
                    <h5 className="font-bold">Kanggo siap membantu kamu</h5>
                    <p className="m-0">Jam operasional kami</p>
                    <ul>
                        <li>
                            Senin-Minggu
                            <strong> 08:00 s/d 20:00</strong>
                        </li>
                    </ul>
                </div>
                <div className="col-12">
                    <Card border>
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <Icon custom icon={iconWhatsapp} />
                            </div>
                            <div className="col-9">
                                <p className="mb-1 font-bold">WhatsApp</p>
                                <span>08119733557</span>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12">
                    <Card border>
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <Icon custom icon={iconEmail} />
                            </div>
                            <div className="col-9">
                                <p className="mb-1 font-bold">Email</p>
                                <span>cs@kanggo.id</span>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12">
                    <Card border>
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <Icon custom icon={iconCall} />
                            </div>
                            <div className="col-9">
                                <p className="mb-1 font-bold">Hubungi Kanggo</p>
                                <span>021-50112100</span>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </HelpWrapper>
    );
};

export default Help;
