/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import Icon from './Icon';

const RowNumberWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    span {
        font-size: 14px;
    }

    a {
        font-size: 12px;
        &> .feather {
            height: 14px;
            width: 14px;
            color: #959598;
        }
    }
`;

interface IRowNumber {
    total: number,
    tooltips?: string
}

const RowNumber: React.FC<IRowNumber> = (props) => {
    const { total, tooltips } = props;
    return (
        <RowNumberWrapper>
            <span>{total}</span>
            <a data-tip={tooltips}>
                <Icon icon="info" />
            </a>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
        </RowNumberWrapper>
    );
};

RowNumber.defaultProps = {
    tooltips: ''
};

export default RowNumber;
