import React from 'react';
import styled from 'styled-components';
import banner from '../../assets/img/banner-kanggo.png';
import Icon from '../../components/Icon';
import { Button, Label, TextInput, TextInputGroup } from '../../components/Styled';

const ForgotPasswordWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    #banner{
        height: 100%;
        img {
            height: 100%;
        }
        
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

interface IForgotPassword {
    email: string
}

class ForgotPassword extends React.Component<any, IForgotPassword> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: ''
        };
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput = (event: { target: { id: string, value: any } }) => {
        const newState = { [event.target.id]: event.target.value } as Pick<IForgotPassword, keyof IForgotPassword>;
        this.setState(newState);
    };

    handleSubmit = () => {
        const { email } = this.state;
        console.log('email', email);
    };

    render(): React.ReactNode {
        const { email } = this.state;
        return (
            <ForgotPasswordWrapper>
                <div id="banner">
                    <img src={banner} alt="PT Tenaga Kanggo Indonesia" />
                </div>
                <div className="container-fluid p-3 p-md-4">
                    <div className="row mb-3">
                        <div className="col-12">
                            <h5 className="font-bold">Lupa Password</h5>
                            <p>Silahkan masukkan email kamu yang terdaftar</p>
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-6">
                            <Label bold htmlFor="email">Masukkan Email</Label>
                            <TextInputGroup border>
                                <TextInput block border id="email" type="email" placeholder="user@kanggo.id" onChange={this.handleInput} value={email} />
                                <div id="input-icon">
                                    <Icon icon="at-sign" />
                                </div>
                            </TextInputGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 text-end">
                            <Button rounded gradient onClick={this.handleSubmit}>Kirim Email</Button>
                        </div>
                    </div>
                </div>
            </ForgotPasswordWrapper>
        );
    }
}

export default ForgotPassword;
