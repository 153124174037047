import React, { useEffect, useState } from 'react';
import { Badge, TabItemLink, TabLayout } from '../../../components/Styled';
import API from '../../../configs/api';

interface ITabLink {
    tabActive: string
}

const TabLink: React.FC<ITabLink> = (props) => {
    const { tabActive } = props;
    const [pendingCount, setPendingCount] = useState(0);
    const [waitingCount, setWaitingCount] = useState(0);
    const [processCount, setProcessCount] = useState(0);

    const tabs = [
        { id: 0, title: 'Menunggu Konfirmasi', status: 'menunggu-konfirmasi', count: pendingCount },
        { id: 1, title: 'Menunggu Pembayaran', status: 'menunggu-pembayaran', count: waitingCount },
        { id: 2, title: 'Dalam Proses', status: 'proses', count: processCount },
        { id: 3, title: 'Pembayaran Gagal', status: 'gagal' },
        { id: 4, title: 'Selesai', status: 'selesai' }
    ];

    const fetchData = async () => {
        try {
            const pending: any = await API.paymentStatusPending();
            const waiting: any = await API.paymentStatusWaiting();
            const process: any = await API.paymentStatusInProgress();
            setPendingCount(pending?.total_data);
            setWaitingCount(waiting?.total_data);
            setProcessCount(process?.total_data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <TabLayout sticky top={60}>
            {tabs.map((tab: any) => (
                <TabItemLink key={tab.id} to={`/status-pembayaran/${tab.status}`} className={tabActive === tab.status ? 'active' : ''}>
                    {tab.title}
                    {tab.count > 0 && (
                        <div style={{
                            position: 'relative',
                            marginLeft: 10,
                            display: 'inline-block'
                        }}
                        >
                            <Badge red style={{ padding: '1px 6px' }}>{tab.count}</Badge>
                        </div>
                    )}
                </TabItemLink>
            ))}
        </TabLayout>
    );
};

export default TabLink;
