/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from './Icon';
import iconBuilding from '../assets/icon/icon-building-circle.svg';
import iconProfile from '../assets/icon/icon-profile.svg';
import iconPassword from '../assets/icon/icon-password.svg';
import iconPasswordCursor from '../assets/icon/icon-password-cursor.svg';
import iconMessage from '../assets/icon/icon-message.svg';
import iconAbout from '../assets/icon/icon-about.svg';
import iconQuestion from '../assets/icon/icon-question.svg';
import iconLogout from '../assets/icon/icon-logout.svg';
import action from '../configs/redux/action';
import { useAppDispatch, useAppSelector } from '../hooks/useAppReducer';

const ProfileMenuWrapper = styled.div<{visible?: boolean}>`
    position: fixed;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    width: auto;
    min-width: 280px;
    padding: 1rem 1.2rem;
    background: var(--color-base-bg-light);
    box-shadow: 0px 2px 19px rgba(167, 167, 167, 0.25);
    border-radius: 10px;
    top: 70px;
    right: 1.5rem;
    user-select: none;
    z-index: 100;
`;

const ProfileMenuHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #EBE7E6;

    a {
        color: var(--color-base-primary-light);
        text-decoration: none;
    }
`;

const ProfileMenuBody = styled.div<{darkMode?: boolean}>`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    img {
        filter: ${(props) => (props.darkMode ? 'brightness(0) invert(1)' : 'none')};
    }
`;

const MenuItem = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;

    a, #logout {
        position: relative;
        display: flex;
        width: 100%;
        padding: 10px .8rem;
        height: auto;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: var(--color-base-text-light);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, .05);
        }

        span {
            margin-left: 10px;
        }
    }
`;

interface IProfileMenu {
    visible: boolean,
    // eslint-disable-next-line no-unused-vars
    onProfileClose: (visible: boolean) => void
}

const ProfileMenu: React.FC<IProfileMenu> = (props) => {
    const { visible, onProfileClose } = props;
    const dispatch = useAppDispatch();
    const { company_name } = useAppSelector((state) => state.userReducer.profile);
    const { darkMode } = useAppSelector((state) => state.uiReducer);

    const onLogout = () => {
        onProfileClose(false);
        dispatch(action.logoutRequest());
    };

    return (
        <>
            {visible && (
                <div id="overlay" onClick={() => onProfileClose(false)} className="transparent" />
            )}
            <ProfileMenuWrapper visible={visible}>
                <ProfileMenuHeader>
                    <Icon custom icon={iconBuilding} />
                    <p className="mt-3 mb-2 p-0 font-bold" style={{ color: 'var(--color-base-text-light)' }}>{company_name}</p>
                    <Link to="/data-kontraktor">Lihat Detail</Link>
                </ProfileMenuHeader>
                <ProfileMenuBody darkMode={darkMode} className="py-3">
                    <MenuItem>
                        <Link to="/profile">
                            <Icon custom icon={iconProfile} />
                            <span>Profile</span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/pin">
                            <Icon custom icon={iconPasswordCursor} />
                            <span>Ubah Pin</span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/password">
                            <Icon custom icon={iconPassword} />
                            <span>Ubah Password</span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/email">
                            <Icon custom icon={iconMessage} />
                            <span>Ubah Email</span>
                        </Link>
                    </MenuItem>
                    <div className="lines my-2" />
                    <MenuItem>
                        <Link to="/tentang">
                            <Icon custom icon={iconAbout} />
                            <span>Tentang Kanggo</span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/bantuan">
                            <Icon custom icon={iconQuestion} />
                            <span>Bantuan</span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <div role="button" id="logout" onClick={() => onLogout()}>
                            <Icon custom icon={iconLogout} />
                            <span>Keluar</span>
                        </div>
                    </MenuItem>
                </ProfileMenuBody>
            </ProfileMenuWrapper>
        </>
    );
};

export default ProfileMenu;
