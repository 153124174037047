/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Pagination from '../Pagination';
import { ActivityWrapper, DateToday } from './ListActivity';
import Icon from '../Icon';
import type { IListActivity } from './ListActivity';

interface IData {
    created_at: string,
    project_name: string,
    address: string,
    note: string,
    role: string
}

interface IItemProject {
    data: IData
}

const ItemProject: React.FC<IItemProject> = ({ data }) => {
    const { created_at, project_name, address, note, role } = data;

    return (
        <div className="py-4 color-text" style={{ borderBottom: '1px solid #D8CFCD' }}>
            <div className="row mx-cs-2">
                <div className="col-2 ps-0">
                    <p className="mb-1 font-small">Tanggal Dibuat</p>
                    <p className="fw-bold mb-0">{created_at}</p>
                </div>
                <div className="col-3">
                    <p className="mb-1 font-small">Nama Proyek</p>
                    <p className="fw-bold mb-0">{project_name}</p>
                </div>
                <div className="col-5">
                    <div className="d-flex justify-content-between w-100 h-100">
                        <div className="d-flex flex-column">
                            <p className="mb-1 font-small">Alamat Proyek</p>
                            <p className="fw-bold mb-0">{address}</p>
                        </div>
                        {note ? (
                            <div className="align-self-center">
                                <a data-tip={`Catatan : ${note}`}>
                                    <Icon icon="info" />
                                </a>
                                <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                />
                            </div>
                        ) : ''}
                    </div>
                </div>
                <div className="col-2">
                    <p className="mb-1 font-small">Posisi</p>
                    <p className="fw-bold mb-0">{role}</p>
                </div>
            </div>
        </div>
    );
};

const ListProject: React.FC<IListActivity> = (props) => {
    const { data, page, from, to, onPageChange } = props;
    return (
        <ActivityWrapper>
            <DateToday />
            {data.total_data > 0 && data.data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ItemProject key={index} data={item} />
            ))}
            <div className="mx-cs-2">
                <Pagination
                    from={from}
                    to={to}
                    totalData={data.total_data}
                    currentPage={page}
                    pageCount={data.total_page}
                    onPageChange={onPageChange}
                />
            </div>
        </ActivityWrapper>
    );
};

export default ListProject;
