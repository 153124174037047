import { useState, useEffect } from 'react';
import API from '../configs/api';

const usePaymentReport = (filter: any, download?: boolean) => {
    const [data, setData] = useState<any>({
        card: {},
        list: []
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleData = (value: any) => {
            setData(value);
        };

        const query: any = {};
        const { page, status, start_date, end_date, limit, project_id } = filter;

        if (page) {
            query.page = page;
        }

        if (status) {
            query.status = status;
        }

        if (start_date) {
            query.start_date = start_date;
        }

        if (end_date) {
            query.end_date = end_date;
        }

        if (project_id) {
            query.project_id = project_id;
        }

        if (limit) {
            query.limit = limit;
        }

        if (download) {
            query.download = download;
        }

        const payload = {
            query
        };

        setLoading(true);
        API.reportPayment(payload).then((result: any) => {
            handleData({ ...result.data, page: result.current_page });
            setLoading(false);
        });

        return () => {
            handleData({
                page: 0,
                card: {},
                list: []
            });
        };
    }, [filter]);

    return { data, loading };
};

export default usePaymentReport;
