import React, { useState } from 'react';
import styled from 'styled-components';
import action from '../configs/redux/action';
import { useAppDispatch } from '../hooks/useAppReducer';
import { Button } from './Styled';
import noImage from '../assets/img/no-image.png';

const RowWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    #name {
        position: relative;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const ProfileCard = styled.div<{ right?: boolean }>`
    position: absolute;
    width: 100%;
    height: auto;
    display: block;
    top: 0;
    z-index: 9;

    .profile {
        position: absolute;
        width: 100%;
        height: auto;
        display: block;
        padding: 5px 0;
        min-width: 300px;
        background: #FFFFFF;
        color: var(--color-base-text-light);
        top: 10px;
        left: 70px;
        border-radius: 20px;
        box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.07);

        .profile-img {
            width: 69px;
            height: 69px;
            margin: auto;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
            }
        }
    }
`;

const ProfileName = styled.span`
    position: relative;
    font-weight: var(--font-bold);
    color: var(--color-primary);
    padding: 5px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
`;

interface IRowProfileCard {
    data: any
}

const RowProfileCard: React.FC<IRowProfileCard> = (props) => {
    const { data } = props;
    const [showCard, setShowCard] = useState(false);
    const dispatch = useAppDispatch();

    const onDetailClick = (id: any) => {
        dispatch(action.drawerProfile(id, true));
    };

    return (
        <RowWrapper onMouseLeave={() => setShowCard(false)}>
            <ProfileName onClick={() => setShowCard(!showCard)}>{data.pm_name ? data.pm_name : data.name}</ProfileName>
            {showCard && (
                <ProfileCard onMouseLeave={() => setShowCard(false)}>
                    <div className="profile px-3">
                        <div className="row my-2 justify-content-between">
                            <div className="col-4">
                                <div className="profile-img">
                                    {data.profile_image || data.pm_profile_image ? (
                                        <img src={data.profile_image ? data.profile_image : data.pm_profile_image} alt={data.pm_name ? data.pm_name : data.name} />
                                    ) : (
                                        <img src={noImage} alt="Kanggo" />
                                    )}
                                </div>
                            </div>
                            <div className="col-8">
                                <p className="mb-2">{data.user_id ? data.user_id : data.pm_id}</p>
                                <p className="mb-1 font-bold font-large" id="name">{data.pm_name ? data.pm_name : data.name}</p>
                                <p className="m-0">{data.role}</p>
                            </div>
                        </div>
                        <div className="lines" />
                        <div className="row my-2">
                            <div className="col">
                                <Button primary rounded>Nonaktifkan</Button>
                            </div>
                            <div className="col">
                                <Button gradient rounded onClick={() => onDetailClick(data.user_id ? data.user_id : data.id)}>Lihat Detail</Button>
                            </div>
                        </div>
                    </div>
                </ProfileCard>
            )}
        </RowWrapper>
    );
};

// RowProfileCard.defaultProps = {
//     right: false
// };

export default RowProfileCard;
