import React from 'react';
import styled from 'styled-components';
import API from '../../configs/api';
import useDate from '../../helpers/useDate';
import DashboardLayout from '../../layout/DashboardLayout';
import LogItem from './components/LogItem';

const LogWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 25px 0 0;
`;

const LogBadge = styled.div`
    position: absolute;
    width: auto;
    padding: 3px 15px;
    background: #fff;
    border: 1px solid #F2B7B9;
    border-radius: 100px;
    top: 0;
    left: 5px;
    z-index: 3;
`;

interface ILogState {
    data: any
}

class Log extends React.Component<any, ILogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        API.log().then((result: any) => {
            const { data } = result;
            const groups = data?.reduce((group: any, log: any) => {
                const created_at = log?.created_at?.split(' ')[0];
                if (!group[created_at]) {
                    group[created_at] = [];
                }
                group[created_at].push(log);
                return group;
            }, {});
            const logs = Object.keys(groups).map((created_at: any) => {
                return {
                    created_at,
                    logs: groups[created_at]
                };
            });
            this.setState({ data: logs });
        });
    };

    render(): React.ReactNode {
        const { data } = this.state;
        return (
            <DashboardLayout activeMenu="log">
                <div className="container-fluid p-3 p-md-4">
                    {data.map((item: any) => (
                        <LogWrapper key={item.created_at}>
                            <LogBadge>
                                {useDate.namedDate(item.created_at)}
                            </LogBadge>
                            <LogItem data={item.logs} />
                        </LogWrapper>
                    ))}
                </div>
            </DashboardLayout>
        );
    }
}

export default Log;
