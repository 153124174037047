import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const DialogWrapper = styled.div<{fullHeight?: boolean, fullWidth?: boolean, noWrap?: boolean}>`
    position: fixed;
    display: block;
    height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    min-height: 250px;
    min-width: 430px;
    max-height: calc(100% - 10%);
    max-width: calc(100% - 30%);
    background: var(--color-white);
    padding: 1.5rem;
    bottom: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, 50%);
    z-index: 102;

    ${(props) => (props.noWrap ? 'max-width: 420px;' : '')}

    @media only screen and (max-width: 768px) {
        max-height: calc(100% - 5%);
        max-width: none;
        border-radius: 20px 20px 0 0;
        bottom: 0;
        left: 0;
        transform: translate(0% , 0%);
    }
`;

const DialogToggle = styled.div`
    position: absolute;
    display: flex;
    top: 1.5rem;
    right: 1.5rem;
    width: 26px;
    height: 26px;
    background: var(--color-white);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    transform: rotate(0);
    transition: .3s ease;
    z-index: 1;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, .06);
    }
`;

interface IDialog {
    fullHeight?: boolean,
    fullWidth?: boolean,
    closeBtn?: boolean,
    noWrap?: boolean,
    // eslint-disable-next-line no-unused-vars
    dialogHandler?: (visible: boolean) => void,
    children: any
}

const Dialog: React.FC<IDialog> = (props) => {
    const { fullHeight, fullWidth, dialogHandler, closeBtn, noWrap, children } = props;
    return (
        <>
            <div id="overlay" />
            <DialogWrapper noWrap={noWrap} fullHeight={fullHeight} fullWidth={fullWidth}>
                {closeBtn && (
                    <DialogToggle onClick={() => (dialogHandler ? dialogHandler(false) : {})}>
                        <Icon large icon="x" />
                    </DialogToggle>
                )}
                {children}
            </DialogWrapper>
        </>
    );
};

Dialog.defaultProps = {
    fullHeight: false,
    fullWidth: false,
    closeBtn: false,
    noWrap: false,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible?: boolean) => {}
};

export default Dialog;
