import axios from 'axios';
import CONSTANT from '../../constant';
import useLocalStorage from '../../hooks/useLocalStorage';

const apiInstance = axios.create({
    baseURL: CONSTANT.API.BASE_URL,
    timeout: 10000
});

class ApiConfig {
    static GET(path: string, token = false) {
        return (payload?: any) => this.request('GET', path, token, payload);
    }

    static POST(path: string, token = false) {
        return (payload?: any) => this.request('POST', path, token, payload);
    }

    static PUT(path: string, token = false) {
        return (payload?: any) => this.request('PUT', path, token, payload);
    }

    static PATCH(path: string, token = false) {
        return (payload?: any) => this.request('PATCH', path, token, payload);
    }

    static DELETE(path: string, token = false) {
        return (payload?: any) => this.request('DELETE', path, token, payload);
    }

    static resolveQueries(queries: string) {
        const queriesResult: any[] = [];
        Object.keys(queries).map((e: any) => queriesResult.push(`${e}=${queries[e]}`));
        return queriesResult.join('&');
    }

    static request(method: string, path: string, token: boolean, payload: any = {}) {
        const baseHeader = {
            'Content-Type': payload.content === 'form-data' ? 'multipart/form-data' : 'application/json',
            'API-KEY': CONSTANT.API.API_KEY,
            Authorization: ''
        };
        if (token) {
            const tokenAuth = useLocalStorage.get(CONSTANT.APP.SESSION_KEY);
            // if (token && !tokenAuth) {
            //     window.location.href = '/';
            // }
            baseHeader.Authorization = tokenAuth;
        }

        return new Promise((resolve, reject) => {
            apiInstance({
                method,
                url: payload.query ? `${path}${payload.params ? payload.params : ''}?${this.resolveQueries(payload.query)}` : `${path}${payload.params ? payload.params : ''}`,
                headers: baseHeader,
                data: payload.body ? payload.body : {}
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err.response);
                if (err && err.response && err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/auth/login';
                }
                if (err && err.response && err.response.data) {
                    reject(err.response.data);
                } else if (err && err.response) {
                    const errs = {
                        statusCode: err.response.status,
                        ...err.response
                    };
                    reject(errs);
                } else if (err) {
                    reject(err);
                }
            });
        });
    }
}

export default ApiConfig;
