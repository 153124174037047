import moment from 'moment';
import 'moment/locale/id';

const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum\'at', 'Sabtu'];

const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

const getMomentDate = (dates?: string, time?:boolean, format?:string) => {
    let formatDate = 'dddd, DD MMMM YYYY';

    if (format) {
        formatDate = format;
    }

    if (time) {
        formatDate += ' | hh:mm';
    }

    const date = moment(dates).format(formatDate);
    return date;
};

const formatDate = (dates?: string, time?:boolean, separator?:string) => {
    let initSeparator = ', ';
    let format = 'DD/MM/YYYY';

    if (separator) {
        initSeparator = separator;
    }

    if (time) {
        format += `${initSeparator}hh:mm`;
    }

    const date = moment(dates).format(format);
    return date;
};

const getCurrentDate = (day = false, month = false, year = false) => {
    const date = new Date();
    let DD: string | number = date.getDate();
    const MM = months[date.getMonth()];
    const YY = date.getUTCFullYear();

    DD = (DD < 10) ? `0${DD}` : DD;

    let currentDate: string | number = '';

    if (day) {
        currentDate = DD;
    }
    if (month) {
        currentDate = `${currentDate} ${MM}`;
    }
    if (year) {
        currentDate = `${currentDate} ${YY}`;
    }
    return currentDate;
};

const getHour = (value: string, format?: string) => {
    let initFormat = 'hh:mm:ss';
    if (format) {
        initFormat = format;
    }
    const result = moment(value).format(initFormat);
    return result;
};

const getDay = () => {
    const date = new Date();
    const day = days[date.getDay()];
    return day;
};

const getDate = () => {
    const date = new Date();
    const DD = date.getDate();
    return DD;
};

const getMonth = () => {
    const date = new Date();
    const MM = months[date.getMonth()];
    return MM;
};

const getYear = () => {
    const date = new Date();
    const YY = date.getUTCFullYear();
    return YY;
};

/**
 *
 * @param hours default true
 * @param minutes default false
 * @param seconds default false
 * @returns currentTime
 */

const getCurrentTime = (hours = true, minutes = false, seconds = false) => {
    const date = new Date();
    let hh: string | number = date.getHours();
    let mm: string | number = date.getMinutes();
    let ss: string | number = date.getSeconds();

    hh = (hh < 10) ? `0${hh}` : hh;
    mm = (mm < 10) ? `0${mm}` : mm;
    ss = (ss < 10) ? `0${ss}` : ss;

    let currentTime: string | number = '';

    if (hours) {
        currentTime = hh;
    }
    if (minutes) {
        currentTime = `${currentTime}${hours ? ':' : ''}${mm}`;
    }
    if (seconds) {
        currentTime = `${currentTime}${minutes ? ':' : ''}${ss}`;
    }
    return currentTime;
};

const splitDate = (value: string) => {
    const date = value.replaceAll('-', '/').split(' ')[0];
    return date;
};

const namedDate = (datetime: string, withTime?: boolean) => {
    let value: string = '';
    const date = datetime.split(' ')[0];
    const time = datetime.split(' ')[1];

    if (parseInt(date.split('-')[2], 10) === getDate()) {
        if (withTime) value = `Hari Ini, ${time}`;
        else value = 'Hari Ini';
    } else if (parseInt(date.split('-')[2], 10) === getDate() - 1) {
        if (withTime) value = `Kemarin, ${time}`;
        else value = 'Kemarin';
    } else {
        value = `${date}${time ? `, ${time}` : ''}`;
    }
    return value;
};

const isSameDate = (datetime: string) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    const engagementDate = new Date(datetime);

    let result = '';
    if (moment(engagementDate).isSame(today, 'day')) {
        result = 'Hari ini,';
    } else if (moment(engagementDate).isSame(yesterday, 'day')) {
        result = 'Kemarin,';
    }

    return result;
};

const useDate = {
    formatDate,
    getMomentDate,
    getCurrentDate,
    getHour,
    getDay,
    getDate,
    getMonth,
    getYear,
    getCurrentTime,
    splitDate,
    namedDate,
    isSameDate
};

export default useDate;
