import React from 'react';
import { Badge } from '../../../components/Styled';

type BadgeStatusProps = {
    status_project: string;
}

const BadgeStatus: React.FC<BadgeStatusProps> = ({ status_project }) => {
    return (
        <Badge warning={status_project === 'PENDING'} success={status_project === 'ACTIVE' || status_project === 'FINISH'} rounded>{status_project}</Badge>
    );
};

export default BadgeStatus;
