import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Login from '../pages/Login';
import HomeDashboard from '../pages/HomeDashboard';
import ContractorProject from '../pages/ContractorProject';
import ContractorData from '../pages/ContractorData';
import Profile from '../pages/Profile';
import Pin from '../pages/Pin';
import Password from '../pages/Password';
import PaymentReport from '../pages/PaymentReport';
import Log from '../pages/Log';
import Help from '../pages/Help';
import About from '../pages/About';

/**
 * Payment Status Page Component
 */
import PaymentStatus from '../pages/PaymentStatus';
import PaymentPending from '../pages/PaymentStatus/views/PaymentPending';
import PaymentWaiting from '../pages/PaymentStatus/views/PaymentWaiting';
import PaymentProcess from '../pages/PaymentStatus/views/PaymentProcess';
import PaymentMethod from '../pages/PaymentStatus/views/PaymentMethod';

/**
 * Member List Page Component
 */
import Member from '../pages/Member';
import ListMember from '../pages/Member/views/ListMember';
import AddMember from '../pages/Member/views/AddMember';
import PaymentFailed from '../pages/PaymentStatus/views/PaymentFailed';
import PaymentSuccess from '../pages/PaymentStatus/views/PaymentSuccess';
import ForgotPassword from '../pages/ForgotPassword';
import UpdateMember from '../pages/Member/views/UpdateMember';

/**
 * Black List Page Component
 */
import Blacklist from '../pages/Blacklist';
import ListBlacklist from '../pages/Blacklist/views/ListBlacklist';
import HistoryBlacklist from '../pages/Blacklist/views/HistoryBlacklist';

const App = () => {
    return (
        <Routes>
            <Route
                index
                element={(
                    <PrivateRoute>
                        <HomeDashboard />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/proyek-kontraktor"
                element={(
                    <PrivateRoute>
                        <ContractorProject />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/data-kontraktor"
                element={(
                    <PrivateRoute>
                        <ContractorData />
                    </PrivateRoute>
                )}
            />
            <Route path="/status-pembayaran" element={<PaymentStatus />}>
                <Route
                    index
                    element={(
                        <PrivateRoute>
                            <PaymentPending />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="menunggu-konfirmasi"
                    element={(
                        <PrivateRoute>
                            <PaymentPending />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="menunggu-pembayaran"
                    element={(
                        <PrivateRoute>
                            <PaymentWaiting />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="proses"
                    element={(
                        <PrivateRoute>
                            <PaymentProcess />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="gagal"
                    element={(
                        <PrivateRoute>
                            <PaymentFailed />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="selesai"
                    element={(
                        <PrivateRoute>
                            <PaymentSuccess />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="bayar/:external_id"
                    element={(
                        <PrivateRoute>
                            <PaymentMethod />
                        </PrivateRoute>
                    )}
                />
            </Route>
            <Route path="/daftar-anggota" element={<Member breadcrumb={['Iya Iya']} />}>
                <Route
                    index
                    element={(
                        <PrivateRoute>
                            <ListMember />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="tambah-anggota"
                    element={(
                        <PrivateRoute>
                            <AddMember />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="update/:user_id"
                    element={(
                        <PrivateRoute>
                            <UpdateMember />
                        </PrivateRoute>
                    )}
                />
            </Route>
            <Route path="/blacklist" element={<Blacklist />}>
                <Route
                    index
                    element={(
                        <PrivateRoute>
                            <ListBlacklist />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="list"
                    element={(
                        <PrivateRoute>
                            <ListBlacklist />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="riwayat"
                    element={(
                        <PrivateRoute>
                            <HistoryBlacklist />
                        </PrivateRoute>
                    )}
                />
            </Route>
            <Route
                path="/laporan-pembayaran"
                element={(
                    <PrivateRoute>
                        <PaymentReport />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/profile"
                element={(
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/password"
                element={(
                    <PrivateRoute>
                        <Password />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/pin"
                element={(
                    <PrivateRoute>
                        <Pin />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/bantuan"
                element={(
                    <PrivateRoute>
                        <Help />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/log"
                element={(
                    <PrivateRoute>
                        <Log />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/tentang"
                element={(
                    <PrivateRoute>
                        <About />
                    </PrivateRoute>
                )}
            />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        </Routes>
    );
};

export default App;
