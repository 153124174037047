export interface ThemeProps {
    background: string,
    text: string
}

export const darkTheme: ThemeProps = {
    background: 'var(--dark-background)',
    text: 'var(--dark-text)'
};

export const lightTheme: ThemeProps = {
    background: 'var(--light-background)',
    text: 'var(--light-text)'
};

export const getTheme = () => {
    const storedTheme = localStorage.getItem('theme');

    const prefersLight = window.matchMedia
        && window.matchMedia('(prefers-color-scheme: light)').matches;

    const defaultLight = storedTheme === 'dark' || (storedTheme === null && prefersLight);
    return defaultLight;
};
