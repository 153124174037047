import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import iconPeopleTeamAdd from '../../../assets/icon/icon-people-team-add.svg';
import iconWallet from '../../../assets/icon/icon-wallet.svg';
import noImage from '../../../assets/img/no-image.png';
import iconLocation from '../../../assets/icon/icon-location-pin.svg';
import useCurrency from '../../../helpers/useCurrency';
import useDate from '../../../helpers/useDate';
import { Button, Card } from '../../../components/Styled';
// import API from '../../../configs/api';
// import staticDetailPayment from '../@static/detail-payment';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';

const ItemProjectDetail = styled.div`
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: #4E4E4E;
    margin: 25px 0;

    .item-project-header {
        position: relative;
        padding: 10px 20px;
        background: #2F2F2F;
        color: #FFF;
        border-radius: 20px 20px 0 0;
    }

    .item-project-body {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px;

        .item-project-detail {
            position: relative;
            display: block;
            width: 100%;
            padding: 15px;
            background: var(--color-white);
            border-radius: 20px;

            img {
                filter: grayscale(100%);
                opacity: 70%;
            }
        }
    }

    .item-project-footer {
        position: relative;
        display: block;
        background: #686868;
        width: 100%;
        margin: 20px 0 0;
        padding: 15px 20px;
        color: #FFF;
        border-radius: 20px;

        .profile-img {
            width: 40px;
            height: 40px;
            margin: auto;

            img {
                height: 100%;
                border-radius: 100px;
            }
        }
    }
`;

const ItemWorkerDetail = styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin: 25px 0;

    .profile-img {
        width: 40px;
        height: 40px;
        margin: auto;

        img {
            height: 100%;
            border-radius: 100px;
        }
    }
`;

const ReasonWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 10px 1.5rem;
    background: #F7F5F5;
    color: #959598;
    border-radius: 100px;

    span {
        color: var(--color-base-primary-light);
    }
`;

interface IDrawerPaymentSuccessItem {
    data: any
}

const DrawerPaymentSuccessItem: React.FC<IDrawerPaymentSuccessItem> = (props) => {
    const { data } = props;
    const dispatch = useAppDispatch();

    const onDetailClick = (id: any) => {
        dispatch(action.drawerProfile(id, true));
    };

    return (
        <>
            <ItemProjectDetail>
                <div className="item-project-header">
                    <div className="row">
                        <div className="col-6">
                            {/* <span>{`${useDate.getDay()}, ${useDate.getCurrentDate(true, true, true)} | ${useDate.getCurrentTime(true, true)}`}</span> */}
                            <span>{useDate.getMomentDate(data.created_at, true)}</span>
                        </div>
                        <div className="col-6 text-end">
                            <span>{data.invoice}</span>
                        </div>
                    </div>
                </div>
                <div className="item-project-body">
                    <div className="item-project-detail">
                        <div id="project-title">
                            <span className="font-large font-bold">{data.project_code}</span>
                            <span className="font-large"> | </span>
                            <span className="font-large">{data.project_name}</span>
                            <p>{data.client_name}</p>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <div id="project-detail">
                                    <p className="m-0">{data.address}</p>
                                    <p className="m-0">
                                        <span style={{ color: '#A5A5A6' }}>Catatan : </span>
                                        <span className="fst-italic" style={{ color: '#2F2F2F' }}>{data.note}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-2 text-end align-item-end">
                                <a href={`https://maps.google.com/?q=${data.latitude},${data.longitude}`} rel="noreferrer" target="_blank">
                                    <Button border primary rounded id="location">
                                        <Icon custom icon={iconLocation} />
                                        Lihat Peta
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <div className="lines my-2" />
                        <div className="row">
                            <div className="col-4 border-end">
                                <p className="mb-1">Anggota yang diajukan</p>
                                <div>
                                    <Icon custom icon={iconPeopleTeamAdd} />
                                    <span className="px-2 align-middle font-bold font-large">{data.total_workers}</span>
                                </div>
                            </div>
                            <div className="col-4 ps-5 border-end">
                                <p className="mb-1">Total Pengajuan</p>
                                <div>
                                    <Icon custom icon={iconWallet} />
                                    <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(data.total_request)}`}</span>
                                </div>
                            </div>
                            <div className="col-4 ps-5">
                                <p className="mb-1">Total Dibayarkan</p>
                                <div>
                                    <Icon custom icon={iconWallet} />
                                    <span className="px-2 align-middle font-bold font-large text-primary">{`Rp${useCurrency(data.total_approved)}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item-project-footer">
                        <div className="row">
                            <div className="col-6">
                                <div className="row my-2 justify-content-between">
                                    <div className="col-profile">
                                        <div className="profile-img">
                                            {data.pm_profile_image ? (
                                                <img src={data.pm_profile_image} alt={data.pm_name} />
                                            ) : (
                                                <img src={noImage} alt="Kanggo" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="mb-1">Project Manager (PM)</p>
                                        <p className="mb-1 font-bold">{data.pm_name}</p>
                                        <p className="m-0">{data.pm_id}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-end">
                                <Button rounded gradient onClick={() => onDetailClick(data.pm_id)}>Lihat Profil</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ItemProjectDetail>
            <div className="row align-items-center">
                <div className="col-12">
                    <p className="mb-2 font-bold">Detail Pengajuan</p>
                    <p className="mb-0">Pengajuan permintaan pembayaran</p>
                </div>
            </div>
            {data && data.mandor.length > 0 && data.mandor.map((item: any) => (
                <React.Fragment key={item.user_id}>
                    <ItemWorkerDetail>
                        <Card shadow>
                            <div className="row justify-content-between">
                                <div className="col-8">
                                    <div className="row my-2 justify-content-between">
                                        <div className="col-profile">
                                            <div className="profile-img">
                                                {item.profile_image ? (
                                                    <img src={item.profile_image ? item.profile_image : item.pm_profile_image} alt={item.name} />
                                                ) : (
                                                    <img src={noImage} alt="Kanggo" />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-1 font-bold">
                                                <span>{item.name}</span>
                                                <span className="text-primary"> Detail Profile</span>
                                            </p>
                                            <p className="mb-1">{item.user_id}</p>
                                            <p className="mb-0" style={{ color: '#959598' }}>{item.role}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <p className="mb-1">Pembayaran Upah</p>
                                    <p className="font-bold" style={{ color: '#EB4600' }}>{`Rp${useCurrency(item.amount)}`}</p>
                                    <div className="row">
                                        <div className="col-8 text-end">
                                            {item.status === 'APPROVE' && (
                                                <Button className="w-100" rounded active bold>Setuju</Button>
                                            )}
                                            {item.status === 'SUCCESS' && (
                                                <Button className="w-100" rounded active bold>Setuju</Button>
                                            )}
                                            {item.status === 'REJECT' && (
                                                <Button className="w-100" border rounded error bold>Tolak</Button>
                                            )}
                                            {item.status === 'AWAITING' && (
                                                <Button className="w-100" border rounded error bold>Menunggu</Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {item.reason && (
                                    <div className="col-12 mt-3">
                                        <ReasonWrapper>
                                            <p className="m-0">Alasan Penolakan</p>
                                            <span>{item.reason}</span>
                                        </ReasonWrapper>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </ItemWorkerDetail>
                </React.Fragment>
            ))}
        </>
    );
};

export default DrawerPaymentSuccessItem;
