import React from 'react';
import styled from 'styled-components';
import illustrasiKanggo from '../../../assets/img/illustrasi-kanggo-send.png';
import Dialog from '../../../components/Dialog';
import { Button } from '../../../components/Styled';

const ImageWrapper = styled.div`
    position: relative;
    display: block;
    height: 140px;
    text-align: center;

    img {
        height: 100%;
    }
`;

interface IDialogProcessPayment {
    onProcessConfim: () => void,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (showDialog: boolean) => void
}

const DialogProcessPayment: React.FC<IDialogProcessPayment> = (props) => {
    const { onProcessConfim, dialogHandler } = props;

    const onConfirm = () => {
        onProcessConfim();
        dialogHandler(false);
    };

    return (
        <Dialog noWrap>
            <ImageWrapper>
                <img src={illustrasiKanggo} alt="PT. Tenaga Kanggo Indonesia" />
            </ImageWrapper>
            <p className="font-bold text-center my-3">Selesaikan Sekarang?</p>
            <p className="text-center mb-3">Seluruh pembayaran akan di transfer langsung ke kanggo saldo masing masing mandor</p>
            <div className="row">
                <div className="col-6">
                    <Button block border rounded textBlack primary onClick={() => dialogHandler(false)}>Batalkan</Button>
                </div>
                <div className="col-6">
                    <Button block rounded gradient onClick={() => onConfirm()}>Proses</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogProcessPayment;
