import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useLocalStorage from '../../../../hooks/useLocalStorage';

interface UIState {
    loading: boolean,
    logoutDialog: boolean,
    breadcrumbs: any[],
    toast: boolean,
    toastType: string,
    toastMessage: string,
    drawerProfileUserId: string | number,
    drawerProfile: boolean,
    drawerDetail: boolean,
    drawerDetailData: any,
    waitingPaymentVisible: boolean,
    waitingPaymentCounter: number,
    pendingPaymentSnackbar: boolean,
    darkMode: boolean
}

const defaultTheme = useLocalStorage.get('dark-mode') || false;

const initialState: UIState = {
    loading: false,
    logoutDialog: false,
    breadcrumbs: [],
    toast: false,
    toastType: '',
    toastMessage: '',
    drawerProfile: false,
    drawerProfileUserId: '',
    drawerDetail: false,
    drawerDetailData: {},
    waitingPaymentVisible: true,
    waitingPaymentCounter: 0,
    pendingPaymentSnackbar: false,
    darkMode: defaultTheme
};

const uiSlice = createSlice({
    name: 'UI',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLogoutDialog: (state, action: PayloadAction<boolean>) => {
            state.logoutDialog = action.payload;
        },
        setToast: (state, action: PayloadAction<any>) => {
            state.toast = action.payload.toast;
            state.toastType = action.payload.type;
            state.toastMessage = action.payload.message;
        },
        setDrawerProfile: (state, action: PayloadAction<any>) => {
            state.drawerProfile = action.payload.visibility;
            state.drawerProfileUserId = action.payload.visibility === false ? '' : action.payload.userId;
        },
        setDrawerDetail: (state, action: PayloadAction<any>) => {
            state.drawerDetail = action.payload.visibility;
            state.drawerDetailData = action.payload.data;
        },
        setCounterWaitingPayment: (state, action: PayloadAction<number>) => {
            state.waitingPaymentCounter = action.payload;
        },
        setWaitingPaymentVisibility: (state, action: PayloadAction<boolean>) => {
            state.waitingPaymentVisible = action.payload;
        },
        setPendingPaymentSnackbar: (state, action: PayloadAction<boolean>) => {
            state.pendingPaymentSnackbar = action.payload;
        },
        setDarkMode: (state, action: PayloadAction<boolean>) => {
            state.darkMode = action.payload;
        },
        setBreadcrumbs: (state, action: PayloadAction<any>) => {
            state.breadcrumbs = action.payload;
        }
    }
});

export const {
    setLoading,
    setLogoutDialog,
    setToast,
    setDrawerProfile,
    setDrawerDetail,
    setCounterWaitingPayment,
    setWaitingPaymentVisibility,
    setDarkMode,
    setBreadcrumbs
} = uiSlice.actions;

export default uiSlice.reducer;
