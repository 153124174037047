import { setDrawerProfile, setDrawerDetail, setLoading, setLogoutDialog, setToast, setCounterWaitingPayment, setWaitingPaymentVisibility } from '../../reducer/ui';

const showLoading = () => (dispatch: any) => {
    dispatch(setLoading(true));
};

const hideLoading = () => (dispatch: any) => {
    dispatch(setLoading(false));
};

const logoutRequest = () => (dispatch: any) => {
    dispatch(setLogoutDialog(true));
};

const logoutCancel = () => (dispatch: any) => {
    dispatch(setLogoutDialog(false));
};

/**
 * Toast action reducer
 * @param type type of toast = success | error
 * @param message toast message
 */
const showToast = (type: string, message: string) => (dispatch: any) => {
    const payload = {
        toast: true,
        type,
        message
    };
    dispatch(setToast(payload));
    setTimeout(() => {
        payload.toast = false;
        payload.type = '';
        payload.message = '';
        dispatch(setToast(payload));
    }, 5000);
};

const drawerProfile = (userId: number | string | any, visibility: boolean) => (dispatch: any) => {
    const payload = {
        userId,
        visibility
    };
    dispatch(setDrawerProfile(payload));
};

const drawerDetail = (data: any, visibility: boolean) => (dispatch: any) => {
    const payload = {
        data,
        visibility
    };
    dispatch(setDrawerDetail(payload));
};

const setWaitingPaymentCounter = (total: number) => (dispatch: any) => {
    dispatch(setCounterWaitingPayment(total));
};

const hideWaitingPayment = () => (dispatch: any) => {
    dispatch(setWaitingPaymentVisibility(false));
};

export {
    showLoading,
    hideLoading,
    logoutRequest,
    logoutCancel,
    showToast,
    drawerProfile,
    drawerDetail,
    hideWaitingPayment,
    setWaitingPaymentCounter
};
