/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Label = styled.label<{bold?: boolean, extraBold?: boolean}>`
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    font-size: var(--font-small);
    margin-bottom: 10px;
    ${(props) => (props.bold ? `
        font-weight: 600;
    ` : '')}
    ${(props) => (props.extraBold ? `
        font-weight: 900;
    ` : '')}
    cursor: pointer;
`;
