import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import illustrasiKanggo from '../assets/img/illustrasi-kanggo-send-email.png';
import { Button } from './Styled';

const SendEmailImageWrapper = styled.div`
    position: relative;
    display: block;
    height: 230px;
    text-align: center;

    img {
        height: 100%;
    }
`;

interface ISendEmailDialog {
    message: string,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (showDialog: boolean) => void
}

const SendEmailDialog: React.FC<ISendEmailDialog> = (props) => {
    const { message, dialogHandler } = props;
    return (
        <Dialog noWrap>
            <SendEmailImageWrapper>
                <img src={illustrasiKanggo} alt="PT. Tenaga Kanggo Indonesia" />
            </SendEmailImageWrapper>
            <p className="font-bold text-center my-3">Berhasil Dikirim!</p>
            <p className="text-center mb-3">{message}</p>
            <div className="row justify-content-center">
                <div className="col-5">
                    <Button block rounded gradient onClick={() => dialogHandler(false)}>OK</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default SendEmailDialog;
