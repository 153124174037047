import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '../../../components/Drawer';
import Icon from '../../../components/Icon';
import DrawerPaymentDetailItem from './DrawerPaymentDetailItem';
import { ButtonIcon } from '../../../components/Styled';
import API from '../../../configs/api';

const DrawerHeader = styled.div`
    position: sticky;
    display: block;
    width: 100%;
    height: auto;
    top: 0;
    padding: .75rem 1.5rem;
    background: #F7F5F5;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, .07);
    z-index: 1;

    button {
        background: #F7F5F5;
        border-color: #D8CFCD;
    }
`;

const DrawerBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: .75rem 0;

    #item {
        position: relative;
        padding: 0 1.5rem;
    }
`;

// const FloatingPayment = styled.div`
//     position: sticky;
//     display: block;
//     width: 90%;
//     height: auto;
//     padding: 10px 0;
//     margin: 0 auto;
//     color: var(--color-white);
//     background: #2F2F2F;
//     border-radius: 100px;
//     bottom: 20px;
//     opacity: 100%;
//     transition: opacity .3s ease;
//     z-index: 99;
// `;

interface IDrawerPaymentDetail {
    data: any,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

const DrawerPaymentDetail: React.FC<IDrawerPaymentDetail> = (props) => {
    const { data, drawerHandler } = props;
    const [staticClose, setStaticClose] = useState(false);
    const [listItem, setListItem] = useState([]);

    const fetchData = () => {
        const payload = {
            query: { external_id: data.external_id }
        };
        API.paymentStatusWaitingDetail(payload).then((result: any) => {
            setListItem(result.data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Drawer noPadding drawerHandler={drawerHandler} staticClose={staticClose} closeIcon={false} right>
            <DrawerHeader>
                <div className="row align-items-center">
                    <div className="col-8">
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <div className="profile-img">
                                    <img src="https://storage.googleapis.com/kang-go.appspot.com/kanggo-kontraktor/profiles/1658916478437.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=UEU%2BFZL5SffRT3u4DJTBWq3W9SfNdB95UHSedehXbq9lU4oVluh0ra6HpEF3NLwM7sFURvTFHL2sT%2FuZrXQC9ToGN7e4TzX%2FpE0ZfLNuZqXfQ9eWwYX0s8vOIrEtuoNNbtgvhuiwh5eB1nGSDNdk2WmFCznAyhGycQWAT%2FmkJNy7hlE4U%2BbntGGi9HZV2Z6gQ7K5xiGd%2B1ln6qFlG3z8%2B5p%2BIWK0cEFXv3c5c9j5jAwf%2BvVfiLUvvkDSzcElQrH9G8XxZZOwbwSL6%2B7Td3XB2pHIhEwzUPAJ%2Fr4e9H0eDPeWbXYYphVACfe%2BbhgjE4EH3gThJ3mNDbG6H0Wsy%2FimmA%3D%3D" alt="Profile" />
                                </div>
                            </div>
                            <div className="col-10">
                                <p className="mb-0">Total transaksi yang dipilih</p>
                                <p className="mb-0 font-bold font-large">{listItem.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 text-end">
                        {/* <Button border rounded textBlack onClick={() => setShowDeleteDialog(true)}>Hapus Semua Transaksi</Button> */}
                    </div>
                    <div className="col-1 text-end">
                        <ButtonIcon onClick={() => setStaticClose(true)}>
                            <Icon icon="x" />
                        </ButtonIcon>
                    </div>
                </div>
            </DrawerHeader>
            <DrawerBody>
                {listItem.map((item: any, idx: any) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={idx}>
                        <div id="item">
                            <DrawerPaymentDetailItem data={item} />
                        </div>
                        <div className="divider" />
                    </React.Fragment>
                ))}
            </DrawerBody>
        </Drawer>
    );
};

export default DrawerPaymentDetail;
