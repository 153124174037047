import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Slider from 'react-slick';
import logoKanggo from '../../assets/img/logo-kanggo-red.png';
import Icon from '../../components/Icon';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Button, Label, TextInput, TextInputGroup } from '../../components/Styled';
import iconEyeClose from '../../assets/icon/icon-close-eye.svg';
import action from '../../configs/redux/action';
// import banner1 from '../../assets/img/banner-login-1.png';
// import banner2 from '../../assets/img/banner-login-2.png';
// import banner3 from '../../assets/img/banner-login-3.png';
import illustrationLogin from '../../assets/img/illustrasi-login.png';
import { resetForm } from '../../configs/redux/reducer/auth';

const SliderWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
`;

const LoginBannerWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
    }

    &#banner-frame {
        width: 100%;
        img {
            height: 160px;
            width: auto;
        }

        #caption {
            display: block;
            width: 100%;
            height: auto;
            font-size: 30px;
            font-weight: var(--font-bold);
            margin: 0;
        }
    }
`;

const SocialWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 20px;
    gap: 10px;

    #social {
        position: relative;
        display: flex;
        background: #F7F5F5;
        width: 38px;
        height: 38px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        text-decoration: none;

        .feather {
            color: #A5A5A6;
        }
    }
`;

const LoginForm = styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 2rem 2.5rem 1rem;
    background: var(--color-white);
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 10px 25px 10px rgba(0, 0, 0, .06);

    @media only screen and (max-width: 768px) {
        box-shadow: none;
    }

    .logo {
        position: relative;
        display: block;
        width: 100%;
        height: 80px;
        margin-bottom: 20px;

        img {
            height: 100%;
        }
    }
`;

interface ILoginState {
    email_phone: string,
    password: string,
    showPassword: boolean
}

interface IILoginProps {
    dispatch: any,
    isLoggedIn: boolean,
    isRequest: boolean,
    error: string
}

class Login extends React.Component<IILoginProps, ILoginState> {
    constructor(props: IILoginProps) {
        super(props);
        this.state = {
            email_phone: '',
            password: '',
            showPassword: false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            window.location.href = '/';
        }
    }

    handleInput = (event: { target: { id: string, value: any } }) => {
        const { dispatch, error } = this.props;
        if (error) {
            dispatch(resetForm());
        }
        const newState = { [event.target.id]: event.target.value } as Pick<ILoginState, keyof ILoginState>;
        this.setState(newState);
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { email_phone, password } = this.state;
        if (!email_phone) {
            return;
        }
        if (!password) {
            return;
        }
        const payload = {
            email_phone,
            password
        };
        dispatch(action.login(payload));
    };

    render(): React.ReactNode {
        const { isRequest, isLoggedIn, error } = this.props;
        const { email_phone, password, showPassword } = this.state;
        const sliderSettings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 2000
        };
        return (
            <>
                {isRequest && (
                    <LoadingOverlay />
                )}
                {isLoggedIn && (
                    <Navigate to="/" replace />
                )}
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-7 col-md-6 d-md-block d-none">
                            <SliderWrapper>
                                <Slider {...sliderSettings}>
                                    <LoginBannerWrapper id="banner-frame">
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <img src={illustrationLogin} alt="PT Tenaga Kanggo Indonesia" />
                                            </div>
                                            <div className="col-12 mb-4">
                                                <h3 id="caption">Pantau dan lakukan pembayaran proyek dengan mudah dan aman.</h3>
                                            </div>
                                            <div className="col-12">
                                                <p className="m-0 font-large">Kamu dapat menambahkan anggota dalam kontraktor. dan menyetujui permintaan pembayaran dalam proyek</p>
                                            </div>
                                            <SocialWrapper>
                                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Kanggo-105141915002139/" id="social">
                                                    <Icon icon="facebook" />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tenagakanggo/" id="social">
                                                    <Icon icon="instagram" />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href="https://kanggo.id/" id="social">
                                                    <Icon icon="globe" />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCuu7PbYnvGlq9fHmDPBcmyA" id="social">
                                                    <Icon icon="youtube" />
                                                </a>
                                            </SocialWrapper>
                                        </div>
                                    </LoginBannerWrapper>
                                </Slider>
                            </SliderWrapper>
                        </div>
                        <div className="col-12 col-md-5">
                            <LoginForm>
                                <div className="logo">
                                    <img src={logoKanggo} alt="Kanggo Finance" />
                                </div>
                                <Label bold htmlFor="email_phone">Email / Nomor Hp</Label>
                                <TextInputGroup error={error.length > 0} style={{ marginBottom: 5 }} border>
                                    <TextInput block id="email_phone" type="text" placeholder="Masukkan email Anda" onChange={this.handleInput} value={email_phone} />
                                </TextInputGroup>
                                <span className="mb-3 d-block text-start text-primary font-small font-bold">{error === 'user' ? 'User tidak ditemukan' : ''}</span>
                                <Label bold htmlFor="password">Password</Label>
                                <TextInputGroup error={error.length > 0} style={{ marginBottom: 5 }} border>
                                    <TextInput block id="password" type={showPassword ? 'text' : 'password'} placeholder="**********" onChange={this.handleInput} value={password} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ showPassword: !showPassword })}>
                                        <Icon custom icon={iconEyeClose} />
                                    </div>
                                </TextInputGroup>
                                <span style={{ marginBottom: 30 }} className="d-block text-start text-primary font-small font-bold">{error === 'password' ? 'Kata sandi salah' : ''}</span>
                                <Button style={{ marginBottom: 30 }} block rounded gradient onClick={this.handleSubmit}>Masuk</Button>
                                <Link to="/auth/forgot-password" className="my-4 font-bold" style={{ color: '#D9272D', textDecoration: 'none' }}>Forgot Password?</Link>
                                <p className="my-4 font-bold" style={{ color: '#959598' }}>Kanggo v.1.44.0</p>
                            </LoginForm>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
        isRequest: state.authReducer.isRequest,
        error: state.authReducer.error
    };
};

export default connect(mapStateToProps)(Login);
