import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/Icon';
import { ButtonIcon } from '../../../components/Styled';
import iconProfile from '../../../assets/icon/icon-profile-gray.svg';
import iconEdit from '../../../assets/icon/icon-edit.svg';
import iconTrash from '../../../assets/icon/icon-trash-gray.svg';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';

const RowWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const PopUpActionWrapper = styled.div`
    position: absolute;
    display: block;
    width: 210px;
    padding: 10px;
    background: var(--color-base-bg-light);
    top: 0;
    right: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
    z-index: 2;
    
    #action-item {
        position: relative;
        display: flex;
        padding: 10px;
        width: 100%;
        margin: 2px 0;
        flex-direction: row;
        justify-content: space-between;
        background: var(--color-base-bg-light);
        align-items: center;

        &:hover {
            border-radius: 10px;
            background: rgba(0, 0, 0, .05);
        }

        p {
            text-align: left;
            margin: 0;
        }
    }
`;

interface IRowMemberAction {
    member: any,
    // eslint-disable-next-line no-unused-vars
    onDeleteClick: (member: any) => void
}

const RowMemberAction: React.FC<IRowMemberAction> = (props) => {
    const { member, onDeleteClick } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showAction, setShowAction] = useState(false);

    const onDetailClick = (id: any) => {
        dispatch(action.drawerProfile(id, true));
    };

    return (
        <RowWrapper onMouseLeave={() => setShowAction(false)}>
            <ButtonIcon onClick={() => setShowAction(true)}>
                <Icon icon="more-vertical" />
            </ButtonIcon>
            {showAction && (
                <PopUpActionWrapper>
                    <div role="button" id="action-item" onClick={() => onDetailClick(member.id)}>
                        <Icon custom icon={iconProfile} />
                        <p>Lihat Anggota</p>
                        <Icon icon="chevron-right" />
                    </div>
                    <div className="lines" />
                    <div role="button" id="action-item" onClick={() => navigate(`/daftar-anggota/update/${member.id}`)}>
                        <Icon custom icon={iconEdit} />
                        <p>Ubah</p>
                        <Icon icon="chevron-right" />
                    </div>
                    <div className="lines" />
                    <div role="button" id="action-item" onClick={() => onDeleteClick(member)}>
                        <Icon custom icon={iconTrash} />
                        <p>Hapus Anggota</p>
                        <Icon icon="chevron-right" />
                    </div>
                </PopUpActionWrapper>
            )}
        </RowWrapper>
    );
};

export default RowMemberAction;
