const renderSwitch = (value: string) => {
    switch (value) {
    case 'PM':
        return 'PM';

    case 'PELAKSANA':
        return 'Pelaksana/Logistik';

    case 'MANDOR':
        return 'Mandor';

    default:
        return value;
    }
};

const numberingTable = (index: number, perPage: number, limitRows: number, pending: boolean) => {
    const rowNumber = (perPage - 1) * limitRows + (index + 1);

    if (!pending) {
        return rowNumber;
    }
    return '';
};

function range(size:number, startAt:number = 0):ReadonlyArray<number> {
    const arr = [];
    for (let index = startAt; index < size; index + 1) {
        arr.push(index);
    }
    return arr;
}

const useText = {
    renderSwitch,
    numberingTable,
    range
};

export default useText;
